import React,{useState,useEffect,useRef} from 'react'
import { Icon } from '@iconify/react';
import editFill from '@iconify/icons-eva/edit-fill';
import { Link as RouterLink } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import outlineRealEstateAgent from '@iconify/icons-ic/outline-real-estate-agent';
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText,Modal,Card,Typography,Button,Grid,Paper,TextField,Table,TableHead,TableRow,TableCell,TableBody,  InputLabel,Select,Stepper,Step,StepLabel
} from '@material-ui/core';
import bxDetail from '@iconify/icons-bx/bx-detail';
import { makeStyles } from '@material-ui/styles';
import { GoogleMap, useJsApiLoader,Marker } from '@react-google-maps/api';
import axios from 'axios';
import getApiUrl from '../../../utils/api'
import List from '../../List/index'

const useStyles = makeStyles({
    delete:{
      position: 'absolute',
      width: '50%',
      height: '300',
      left: '25%',
      top: '25%',
    },
    detail:{ 
      width: '80%',
      position:'absolute',
      top:'0',
      left:'0',
      overflowY:'scroll',
    },
    paper:{
      padding:'5%'
    },
    grid:{ 
      paddingTop:'5%'
    },
    input:{
      width:'100%',
    },
    containerStyle:{
      width:'100%',
      height:'50vh',
    },
  
    imgWrap:{
      position:'relative',
      overflowX:'scroll',
      width:'100%',
      display:'grid',
      height:'200px',
      gridTemplateColumns:'repeat(30,1fr)'
    },
    imgContainer:{
      border:'1px solid black',
      width:'200px',
    },
    img:{ 
      width:'100%',
      height:'auto',
    }, 
    row:{
      backgroundColor:'#81c784',
      width:'100%',
    },
    edit:{
      width: '80%',
      position:'absolute',
      top:'0',
      left:'0',
      overflowY:'scroll',
    },
    textField:{
      width:'100%',
    },
    btn:{
      width:'100%',
      height:'100%',
    },
    imgD:{
      width:'24px'
    },
    status:{
      alignItems:'center',
      display:'flex',
      justifyContent:'center',
      flexDirection:'column',
      padding:'2%'
    },
    statusModal:{
      position:'relative',
      width:'60%',
      left:'20%'
    },
    step:{
      padding:'2%'
    },
    none:{
      backgroundColor:'#81c784'
    },
   


})
const url = getApiUrl();
const steps =[ 'Selling','Pending','Sold'];

export default function CommercialMoreMenu({row,i,handleDeleteCommercial,commercial,setCommercial}) {

    const classes = useStyles()
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isDelete,setIsDelete] = useState(false);
  const [isEdit,setIsEdit] = useState(false);
  const [isDetail,setIsDetail] = useState(false);
  const [value,setValue] = useState(row)
  const [previewLink,setPreviewLink] = useState(row.images)
  const [imgUrl,setImgUrl] = useState(row.images)
  const [id,setId] = useState('')
  const [point,setPoint] = useState({lat:40.75218399834244,lng:-73.82699301800103});

  const [investmentHighlight,setInvestHighlight] = useState(row.investmentHighlight)
  const [nearbyRestaurant,setNearByRestaurant] = useState(row.nearbyRestaurant);
  const [nearbyRetail,setNearByRetail] = useState(row.nearbyRetail);
  const [subway,setSubway] = useState(row.subway);
  const [commuterRail,setCommuterRail] = useState(row.commuterRail);
  const [airport,setAirPort] = useState(row.airport);

  const apiKey = process.env.REACT_APP_MAP_API
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey:apiKey
  })

  const [likeUser,setLikeUser]= useState([])

 
 
  const handleDeleteOpen = ()=>{
    setIsDelete(true)
    setId(row._id)

  }
  const handleDeleteClose = ()=>{
    setIsDelete(false)
  }

  const handleEditOpen = ()=>{
    setIsEdit(true)
    setId(row._id)
    
  }
  const handleEditClose = ()=>{
    setIsEdit(false)
  }
  const fetchAllUser = async(id)=>{
    const result = await axios(`${url}/commercial/like`,{params:{commercialId:id}})
    setLikeUser(result.data.data)
}


  const handleDetailOpen = ()=>{
     setId(row._id)
        fetchAllUser(row._id)
    setIsDetail(true)

  }
  const handleDetailClose = ()=>{
    setIsDetail(false)
  }
  const handleDeleteModalClose = ()=>{
    setIsDelete(false)
    setIsOpen(false)
  }

  const handleDeleteSure = (id,i)=>{

    handleDeleteCommercial(id,i)


    setIsDelete(false)
    setIsOpen(false)
  }


  const handleInputPrice = (e)=>{
    setValue({...value,price:e.target.value});
}

const handleInputAddress =(e)=>{
    setValue({...value,address:e.target.value});
}

const handleInputZipCode = (e)=>{
    setValue({...value,zipCode:e.target.value});
}

const handleInputCity = (e)=>{
    setValue({...value, city: e.target.value})
}

const handleInputLat=(e)=>{
    setValue({...value,lat: e.target.value})
}

const handleInputLng = (e)=>{
    setValue({...value,lng: e.target.value})
}

const handleInputDescription =(e)=>{
    setValue({...value, description: e.target.value})
}

const handleInputVirtualTour = (e)=>{
    setValue({...value,virtualTour: e.target.value})
}

const handleInputState = (e)=>{
    setValue({...value,state: e.target.value})
}
const handleInputCapRate = (e)=>{
    setValue({...value,capRate: e.target.value})
}



const handleInputSaleType = (e)=>{
  setValue({...value,saleType: e.target.value})
}

const handleInputSaleConditions = (e)=>{
    setValue({...value,saleConditions: e.target.value})
}

const handleInputPropertyTypes = (e)=>{
    setValue({...value,propertyTypes: e.target.value})
}

const handleInputBuildingSizes = (e)=>{
    setValue({...value,buildingSizes: e.target.value})
}

const handleInputBuildingClass = (e)=>{
    setValue({...value,buildingClass: e.target.value})
}
const handleInputYearBuild = (e)=>{
    setValue({...value,yearBuild: e.target.value})
}

const handleInputRenovated = (e)=>{
    setValue({...value,renovated: e.target.value})
}
const handleInputParking = (e)=>{
    setValue({...value,parking: e.target.value})
}
const handleInputZoning = (e)=>{
    setValue({...value,zoning: e.target.value})
}
const handleInputOpportunityZones = (e)=>{
    setValue({...value,opportunityZones: e.target.value})
}

const handleInputPricePerSF = (e)=>{
    setValue({...value,pricePerSF: e.target.value})
}
const handleInputNol = (e)=>{
    setValue({...value,nol: e.target.value})
}
const handleInputTenancy = (e)=>{
    setValue({...value,tenancy: e.target.value})
}
const handleInputBuildingHeight = (e)=>{
    setValue({...value,buildingHeight: e.target.value})
}
const handleInputBuildingFar = (e)=>{
    setValue({...value,buildingFar: e.target.value})
}
const handleInputLandAcres = (e)=>{
    setValue({...value,landAcres: e.target.value})
}
const handleInputAbout = (e)=>{
    setValue({...value,about: e.target.value})
}

const handlePropertyTax = (e)=>{
    setValue({...value,propertyTax: e.target.value})
}
const handlePropertySubtype = (e)=>{
    setValue({...value,propertySubtype: e.target.value})
}
const handleVideoUrl = (e)=>{
    setValue({...value,videoUrl: e.target.value})
}

const handleAgentPhone = (e)=>{
    setValue({...value,agentPhone: e.target.value})
}
const handleAgentEmail = (e)=>{
    setValue({...value,agentEmail: e.target.value})
}

const handleFileUploadImg = async(e)=>{
  const {files} = e.target
  const formData = new FormData();
  const arr = [...previewLink];

  for(let i =0;i<files.length;i+=1){
    formData.append('photos',files[i])
          const link = URL.createObjectURL(files[i])
         arr.push(link);
  }
  // 别问 问就是他妈的批量上传的锅
  setPreviewLink(arr)

  const uploadResult = await axios.post(`${url}/file`, formData)
  const arr2 =uploadResult.data.data;
       
  const temp =imgUrl.concat(arr2)
  setPreviewLink(temp)
}

const handleFileUpload = async(e) =>{
const file = e.target.files[0];
const formData = new FormData();

formData.append('file',file)

  const result = await axios.post(`${url}/file/file`, formData)
  setValue({...value,document:result.data.data})
}

const handleSubmit = async()=>{
    const {
        price,
        address,
        zipCode,
        city,
        lat,
        lng,
        description,
        virtualTour,
        state,
        capRate,
        document,
        saleType,
        saleConditions,
        propertyTypes,
        buildingSizes,
        buildingClass,
        yearBuild,
        renovated,
        parking,
        zoning,
        opportunityZones,
        pricePerSF,
        nol,
        tenancy,
        buildingHeight,
        buildingFar,
        landAcres,
        about,
        propertyTax,
        propertySubtype,
        videoUrl,
        agentPhone,
        agentEmail

    } = value;

    if(!price) alert('Missing price')
    if(!address) alert('Missing address')
    if(!zipCode) alert('Missing zip code')
    if(!city) alert('Missing city')
    if(!state) alert('Missing state')

    const images = previewLink;
    const commercialId = id

    const result = await axios.post(`${url}/commercial/update`,{
        commercialId,
        price,
           address,
           zipCode,
           city,
           lat,
           lng,
           images,
           description,
           virtualTour,
           state,
           capRate,
           investmentHighlight,
           document,
           saleType,
           saleConditions,
           propertyTypes,
           buildingSizes,
           buildingClass,
           yearBuild,
           renovated,
           parking,
           zoning,
           opportunityZones,
           pricePerSF,
           nol,
           tenancy,
           buildingHeight,
           buildingFar,
           landAcres,
           about,
           nearbyRestaurant,
           nearbyRetail,
           subway,
           commuterRail,
           airport,
           propertyTax,
           propertySubtype,
           videoUrl,
           agentPhone,
           agentEmail
    })

    if(result.data.success){
        const temp =[...commercial]
        temp[i] = result.data.data
        setCommercial(temp)
            setIsEdit(false)
            setIsOpen(false)

      }
      else{
        alert(`This is an error alert — ${result.data.data}!`)
      }

}

const handleDeleteImg = (i)=>{

    const arr = [...previewLink];
    arr.splice(i, 1);
    setPreviewLink(arr)
  }

    return (
       <>
        <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem sx={{ color: 'text.secondary' }} onClick={()=>handleDeleteOpen()}>
          <ListItemIcon>
            <Icon icon={trash2Outline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        <MenuItem component={RouterLink} to="#" onClick={()=>handleEditOpen()} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        <MenuItem component={RouterLink} to="#" onClick={()=>handleDetailOpen()} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Icon icon={bxDetail} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Detail" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        {/* <MenuItem component={RouterLink} to="#" onClick={()=>handleStatusOpen()} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Icon icon={outlineRealEstateAgent} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Status" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem> */}

      </Menu>

      <Modal
        className={classes.delete}
        open={isDelete} 
        onClose={()=>handleDeleteClose()}
      >
        <Card>
        <Typography align="center">Are you sure you want delete this?</Typography>
        <Grid container >
       
          <Grid item xs={6}>
            <Paper align="center">
              <Button onClick={()=>handleDeleteSure(row._id,i)} >Delete</Button>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper align="center">
            <Button  onClick={()=>handleDeleteModalClose()}>Cancel</Button>
            </Paper>
           
          </Grid>
        
        </Grid>
       
      
        </Card>
      </Modal>

      <Modal
       open={isDetail}
       onClose={()=>handleDetailClose()}
       className={classes.detail}
      >
          <Paper className={classes.paper}>
          <Typography variant="h3">商业地产详情</Typography>

          <Grid container spacing={4}> 

<Grid item xs={4}>
<TextField required className={classes.textField}   label='Price' type='number' defaultValue={value.price}  InputProps={{
            readOnly: true,
          }}/>
</Grid>

<Grid item xs={8}>
<TextField required className={classes.textField}   label='Address' type='text' defaultValue={value.address}  InputProps={{
            readOnly: true,
          }}/>
</Grid>

<Grid item xs={3}>
<TextField required className={classes.textField}   label='ZipCode' type='text' defaultValue={value.zipCode}  InputProps={{
            readOnly: true,
          }}/>
</Grid>

<Grid item xs={3}>
<TextField required className={classes.textField}   label='City' type='text' defaultValue={value.city} InputProps={{
            readOnly: true,
          }}/>
</Grid>

<Grid item xs={3}>
<TextField required className={classes.textField}   label='State' type='text' defaultValue={value.state}  InputProps={{
            readOnly: true,
          }}/>
</Grid>

<Grid item xs={3}>
<TextField  className={classes.textField}   label='CapRate' type='text' defaultValue={value.capRate}  InputProps={{
            readOnly: true,
          }}/>
</Grid>

<Grid item xs={6}>
<TextField  className={classes.textField}   label='SaleType' type='text' defaultValue={value.saleType}  InputProps={{
            readOnly: true,
          }}/>
</Grid>

<Grid item xs={6}>
<TextField  className={classes.textField}   label='SaleCondition' type='text' defaultValue={value.saleConditions}  InputProps={{
            readOnly: true,
          }}/>
</Grid>

<Grid item xs={6}>
<TextField  className={classes.textField}   label='PropertyTypes' type='text' defaultValue={value.propertyTypes}  InputProps={{
            readOnly: true,
          }}/>
</Grid>


<Grid item xs={3}>
<TextField  className={classes.textField}   label='YearBuild' type='number' defaultValue={value.yearBuild} InputProps={{
            readOnly: true,
          }}/>
</Grid>

<Grid item xs={3}>
<TextField  className={classes.textField}   label='Renovated' type='String' defaultValue={value.renovated} InputProps={{
            readOnly: true,
          }}/>
</Grid>

<Grid item xs={6}>
<TextField  className={classes.textField}   label='BuildingSizes' type='String' defaultValue={value.buildingSizes} InputProps={{
            readOnly: true,
          }} />
</Grid>

<Grid item xs={6}>
<TextField  className={classes.textField}   label='BuildingClass' type='String' defaultValue={value.buildingClass}  InputProps={{
            readOnly: true,
          }}/>
</Grid>

<Grid item xs={6}>
<TextField  className={classes.textField}   label='Parking' type='String' defaultValue={value.parking} InputProps={{
            readOnly: true,
          }} />
</Grid>

<Grid item xs={6}>
<TextField  className={classes.textField}   label='Zoning' type='String' defaultValue={value.zoning} InputProps={{
            readOnly: true,
          }}/>
</Grid>

<Grid item xs={6}>
<TextField  className={classes.textField}   label='OpportunityZones' type='String' defaultValue={value.opportunityZones} InputProps={{
            readOnly: true,
          }}/>
</Grid>

<Grid item xs={3}>
<TextField  className={classes.textField}   label='PricePerSF' type='String' defaultValue={value.pricePerSF}  InputProps={{
            readOnly: true,
          }}/>
</Grid>

<Grid item xs={3}>
<TextField  className={classes.textField}   label='Nol' type='String' defaultValue={value.nol} InputProps={{
            readOnly: true,
          }}/>
</Grid>

<Grid item xs={4}>
<TextField  className={classes.textField}   label='Tenancy' type='String' defaultValue={value.tenancy}  InputProps={{
            readOnly: true,
          }}/>
</Grid>

<Grid item xs={4}>
<TextField  className={classes.textField}   label='BuildingHeight' type='String' defaultValue={value.buildingHeight}  InputProps={{
            readOnly: true,
          }}/>
</Grid>

<Grid item xs={4}>
<TextField  className={classes.textField}   label='BuildingFar' type='String' defaultValue={value.buildingFar}  InputProps={{
            readOnly: true,
          }}/>
</Grid>

<Grid item xs={4}>
<TextField  className={classes.textField}   label='LandAcres' type='String' defaultValue={value.landAcres} InputProps={{
            readOnly: true,
          }}/>
</Grid>

<Grid item xs={4}>
<TextField  className={classes.textField}   label='PropertyTax' type='String' defaultValue={value.propertyTax}  InputProps={{
            readOnly: true,
          }}/>
</Grid>

<Grid item xs={4}>
<TextField  className={classes.textField}   label='PropertySubtype' type='String' defaultValue={value.propertySubtype}  />
</Grid>

<Grid item xs={12}>
<TextField multiline  className={classes.textField}   label='Description' type='String' defaultValue={value.description}  InputProps={{
            readOnly: true,
          }}/>
</Grid>

<Grid item xs={6}>
<TextField   className={classes.textField}   label='AgentPhone' type='String' defaultValue={value.agentPhone}  InputProps={{
            readOnly: true,
          }}/>
</Grid>

<Grid item xs={6}>
<TextField  className={classes.textField}   label='AgentEmail' type='String' defaultValue={value.agentEmail}  InputProps={{
            readOnly: true,
          }}/>
</Grid>


<Grid item xs={12}>
<TextField multiline  className={classes.textField}   label='VirtualTour' type='String' defaultValue={value.virtualTour}  InputProps={{
            readOnly: true,
          }}/>
</Grid>

<Grid item xs={12}>
<TextField multiline  className={classes.textField}   label='About' type='String' defaultValue={value.about}  InputProps={{
            readOnly: true,
          }}/>
</Grid>

<Grid item xs={12}>
<TextField multiline  className={classes.textField}   label='VideoUrl' type='String' defaultValue={value.videoUrl}  />
</Grid>

<List readOnly list={value.investmentHighlight} label='InvestmentHighlight'   />

<List readOnly list={value.nearbyRestaurant} label='NearByRestaurant'   />

<List readOnly list={value.nearbyRetail} label='NearByRetail'   />

<List readOnly list={value.subway} label='Subway'   />

<List readOnly list={value.commuterRail} label='CommuterRail'   />

<List readOnly list={value.airport} label='AirPort'   />

<Grid item xs={5}>
    <TextField  InputProps={{
            readOnly: true,
          }} className={classes.textField}   label='latitude' type='String' defaultValue={value.lat} />

    </Grid>

    <Grid item xs={5}>
    <TextField   InputProps={{
            readOnly: true,
          }}  className={classes.textField}   label='longitude' type='String' defaultValue={value.lng}  />

    </Grid>

  

    <Grid item xs={12}>
 
 {isLoaded&&<GoogleMap
 mapContainerClassName={classes.containerStyle}
 center={point}
 zoom={15}

 >
 <Marker
 
 position={{lat:Number(value.lat),lng:Number(value.lng)}}
 />
 </GoogleMap>}
</Grid>





<Grid item xs={12}>
  <Typography variant="h3">上传图片</Typography>
  </Grid>
   {/* <Grid item xs={3}> <input type="file" multiple  readOnly/></Grid> */}
 
    {
      previewLink&&imgUrl.map((v,i)=>(
        <Grid item xs={3} key={i}><img className={classes.img} src={v} alt="" /></Grid>
      ))
         
    }
    <Grid item xs={12}>
        <Typography variant="h3">上传文件</Typography>
        <a target="blank" download href={value.document} >
            Document
        </a>
  </Grid>
  <Grid item xs={12}>

  <Typography variant="h3">感兴趣的用户</Typography>
                  </Grid>
                    <Table>

                      <TableHead>
                        <TableRow className={classes.row}>
                          <TableCell>
                            <Typography>Name</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>Phone</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>Email</Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                       
                        {
                    likeUser&&likeUser.map((v,i)=>(
                      <TableRow key={i}>
                        <TableCell align='left'>
                          {v.userName}
                        </TableCell>
                        <TableCell align='left'>
                        {v.phone}
                      </TableCell>
                      <TableCell align='left'>
                      {v.email}
                    </TableCell>
                    </TableRow>

                    ))
                  }

                      </TableBody>

                    </Table>
 




</Grid> 


    </Paper>



      </Modal>


    <Modal  className={classes.edit}
      open={isEdit}
      onClose={()=>handleEditClose()}>
        <Paper className={classes.paper}>

        <Typography variant="h3">更改商业地产</Typography>  

        <Grid container spacing={4}> 

<Grid item xs={4}>
<TextField required className={classes.textField}   label='Price' type='number' defaultValue={value.price} onChange={(e)=>handleInputPrice(e)} />
</Grid>

<Grid item xs={8}>
<TextField required className={classes.textField}   label='Address' type='text' defaultValue={value.address} onChange={(e)=>handleInputAddress(e)} />
</Grid>

<Grid item xs={3}>
<TextField required className={classes.textField}   label='ZipCode' type='text' defaultValue={value.zipCode} onChange={(e)=>handleInputZipCode(e)} />
</Grid>

<Grid item xs={3}>
<TextField required className={classes.textField}   label='City' type='text' defaultValue={value.city} onChange={(e)=>handleInputCity(e)} />
</Grid>

<Grid item xs={3}>
<TextField required className={classes.textField}   label='State' type='text' defaultValue={value.state} onChange={(e)=>handleInputState(e)} />
</Grid>

<Grid item xs={3}>
<TextField  className={classes.textField}   label='CapRate' type='text' defaultValue={value.capRate} onChange={(e)=>handleInputCapRate(e)} />
</Grid>

<Grid item xs={6}>
<TextField  className={classes.textField}   label='SaleType' type='text' defaultValue={value.saleType} onChange={(e)=>handleInputSaleType(e)} />
</Grid>

<Grid item xs={6}>
<TextField  className={classes.textField}   label='SaleCondition' type='text' defaultValue={value.saleConditions} onChange={(e)=>handleInputSaleConditions(e)} />
</Grid>

<Grid item xs={6}>
<TextField  className={classes.textField}   label='PropertyTypes' type='text' defaultValue={value.propertyTypes} onChange={(e)=>handleInputPropertyTypes(e)} />
</Grid>


<Grid item xs={3}>
<TextField  className={classes.textField}   label='YearBuild' type='number' defaultValue={value.yearBuild} onChange={(e)=>handleInputYearBuild(e)} />
</Grid>

<Grid item xs={3}>
<TextField  className={classes.textField}   label='Renovated' type='String' defaultValue={value.renovated} onChange={(e)=>handleInputRenovated(e)} />
</Grid>

<Grid item xs={6}>
<TextField  className={classes.textField}   label='BuildingSizes' type='String' defaultValue={value.buildingSizes} onChange={(e)=>handleInputBuildingSizes(e)} />
</Grid>

<Grid item xs={6}>
<TextField  className={classes.textField}   label='BuildingClass' type='String' defaultValue={value.buildingClass} onChange={(e)=>handleInputBuildingClass(e)} />
</Grid>

<Grid item xs={6}>
<TextField  className={classes.textField}   label='Parking' type='String' defaultValue={value.parking} onChange={(e)=>handleInputParking(e)} />
</Grid>

<Grid item xs={6}>
<TextField  className={classes.textField}   label='Zoning' type='String' defaultValue={value.zoning} onChange={(e)=>handleInputZoning(e)} />
</Grid>

<Grid item xs={6}>
<TextField  className={classes.textField}   label='OpportunityZones' type='String' defaultValue={value.opportunityZones} onChange={(e)=>handleInputOpportunityZones(e)} />
</Grid>

<Grid item xs={3}>
<TextField  className={classes.textField}   label='PricePerSF' type='String' defaultValue={value.pricePerSF} onChange={(e)=>handleInputPricePerSF(e)} />
</Grid>

<Grid item xs={3}>
<TextField  className={classes.textField}   label='Nol' type='String' defaultValue={value.nol} onChange={(e)=>handleInputNol(e)} />
</Grid>

<Grid item xs={4}>
<TextField  className={classes.textField}   label='Tenancy' type='String' defaultValue={value.tenancy} onChange={(e)=>handleInputTenancy(e)} />
</Grid>

<Grid item xs={4}>
<TextField  className={classes.textField}   label='BuildingHeight' type='String' defaultValue={value.buildingHeight} onChange={(e)=>handleInputBuildingHeight(e)} />
</Grid>

<Grid item xs={4}>
<TextField  className={classes.textField}   label='BuildingFar' type='String' defaultValue={value.buildingFar} onChange={(e)=>handleInputBuildingFar(e)} />
</Grid>

<Grid item xs={4}>
<TextField  className={classes.textField}   label='LandAcres' type='String' defaultValue={value.landAcres} onChange={(e)=>handleInputLandAcres(e)} />
</Grid>

<Grid item xs={4}>
<TextField  className={classes.textField}   label='PropertyTax' type='String' defaultValue={value.propertyTax} onChange={(e)=>handlePropertyTax(e)} />
</Grid>

<Grid item xs={4}>
<TextField  className={classes.textField}   label='PropertySubtype' type='String' defaultValue={value.propertySubtype} onChange={(e)=>handlePropertySubtype(e)} />
</Grid>

<Grid item xs={12}>
<TextField multiline  className={classes.textField}   label='Description' type='String' defaultValue={value.description} onChange={(e)=>handleInputDescription(e)} />
</Grid>

<Grid item xs={6}>
<TextField   className={classes.textField}   label='AgentPhone' type='String' defaultValue={value.agentPhone} onChange={(e)=>handleAgentPhone(e)} />
</Grid>

<Grid item xs={6}>
<TextField  className={classes.textField}   label='AgentEmail' type='String' defaultValue={value.agentEmail} onChange={(e)=>handleAgentEmail(e)} />
</Grid>


<Grid item xs={12}>
<TextField multiline  className={classes.textField}   label='VirtualTour' type='String' defaultValue={value.virtualTour} onChange={(e)=>handleInputVirtualTour(e)} />
</Grid>

<Grid item xs={12}>
<TextField multiline  className={classes.textField}   label='About' type='String' defaultValue={value.about} onChange={(e)=>handleInputAbout(e)} />
</Grid>

<Grid item xs={12}>
<TextField multiline  className={classes.textField}   label='VideoUrl' type='String' defaultValue={value.videoUrl} onChange={(e)=>handleVideoUrl(e)} />
</Grid>

<List list={investmentHighlight} label='InvestmentHighlight' setList={setInvestHighlight}  />

<List list={nearbyRestaurant} label='NearByRestaurant' setList={setNearByRestaurant}  />

<List list={nearbyRetail} label='NearByRetail' setList={setNearByRetail}  />

<List list={subway} label='Subway' setList={setSubway}  />

<List list={commuterRail} label='CommuterRail' setList={setCommuterRail}  />

<List list={airport} label='AirPort' setList={setAirPort}  />

<Grid item xs={5}>
    <TextField  className={classes.textField}   label='latitude' type='String' defaultValue={value.lat} onChange={(e)=>handleInputLat(e)} />

    </Grid>

    <Grid item xs={5}>
    <TextField  className={classes.textField}   label='longitude' type='String' defaultValue={value.lng} onChange={(e)=>handleInputLng(e)} />

    </Grid>

    <Grid item xs={2}>
                <Button variant='contained' className={classes.check} >Check</Button>
    </Grid>

    <Grid item xs={12}>
 
 {isLoaded&&<GoogleMap
 mapContainerClassName={classes.containerStyle}
 center={{lat:point.lat,lng:point.lng}}
 zoom={15}

 >
 <Marker
 
 position={point}
 />
 </GoogleMap>}
</Grid>





<Grid item xs={12}>
<Typography variant="h3">上传图片</Typography>
            </Grid>
             <Grid item xs={3}> <input type="file" multiple onChange={(e)=>handleFileUploadImg(e)} /></Grid>
           
              {
                previewLink&&previewLink.map((v,i)=>(
                  <Grid item xs={3} key={i}>
                      <img className={classes.img} src={v} alt="" />

                  <Button onClick={()=>handleDeleteImg(i)}>

                  <img className={classes.imgD} src="https://res.cloudinary.com/dlapk94rx/image/upload/v1628537791/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/remove_ebe72q.png" alt=""  />
                  </Button>
                 
                  </Grid>
                ))
                   
              }
    <Grid item xs={12}>
  <Typography variant="h3">上传文件</Typography>
  </Grid>
   <Grid item xs={3}> <input type="file"  onChange={(e)=>handleFileUpload(e)} />
   </Grid>



<Grid item xs={12}>
    <Button onClick={()=>handleSubmit()} className={classes.check} variant='contained' >Submit</Button>
</Grid>
</Grid> 
</Paper>



        

    </Modal>



      
        
       </>
    )
}
