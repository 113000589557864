// import * as Yup from 'yup';
import { useState } from 'react';
import {  useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  // Link,
  Stack,
  // Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  // FormControlLabel
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import getApiUrl  from '../../../utils/api';


// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [phone,setPhone] = useState('')
  const [password,setPassword] = useState('')

  const apiUrl = getApiUrl();
  const handlePhoneChange=(e)=>{
    setPhone(e.target.value);
  }

  const handlePasswordChange=(e)=>{
    setPassword(e.target.value);

  }

  // const formik = useFormik({
    
  //   onSubmit: () => {
  //     navigate('/dashboard', { replace: true });
  //   }
  // });

  // const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleSubmit = async() => {
    if(!phone){alert("Please enter a phone number")}
    if(!password){alert("Please enter a password")}

        try {
          
            const result =await axios.get(`${apiUrl}/auth/admin`,{params:{
              phone,
              password
          }})
            if(result.data.success){
                    // const token = result.data.token;
                    // const adminId= result.data.adminId;
                    // const role = result.data.role;
                    const {token,role,adminId,name} = result.data;
                    // setToken(token)
                    // setRole(role)

                    window.localStorage.setItem('token',token);
                    window.localStorage.setItem('role',role);
                    window.localStorage.setItem('adminId',adminId);
                    window.localStorage.setItem('name',name)
                    // history.history.push('/')
                    navigate('/dashboard', { replace: true });
            }
            else{
                alert('Wrong Password op Phone Number')
            }

        } catch (e) {
            alert(e);
        }

  }

  return (
   
      <div>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="number"
            label="Phone"
            onChange={(e)=>handlePhoneChange(e)}
            value={phone}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            onChange={(e)=>handlePasswordChange(e)}
            value={password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Stack>

        {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            control={<Checkbox  />}
            label="Remember me"
          />

          <Link component={RouterLink} variant="subtitle2" to="#">
            Forgot password?
          </Link>
        </Stack> */}
        <br/>
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          // loading={isSubmitting}
          onClick={()=>handleSubmit()}
        >
          Login
        </LoadingButton>
      </div>
  );
}
