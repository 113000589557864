const devUrl = 'http://localhost:8080';

const prodUrl = 'https://pyrhomes.com';

const currentEnv = process.env.REACT_APP_ENV

const getApiUrl = ()=>{
    if(currentEnv === 'prod'){
        return prodUrl;
    }
    
        return devUrl;
    

}

export default getApiUrl;