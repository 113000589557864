import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
// import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
import Products from './pages/Realty';
import Blog from './pages/Rent';
import User from './pages/User';
import NotFound from './pages/Page404';
import Agent from './pages/Agent';
import Recom from './pages/Recom';
import Lord from './pages/Lord';
import Commercial from './pages/Commercial';
import CommercialRent from './pages/CommercialRent';
import Land from './pages/Land';

// ----------------------------------------------------------------------

export default function Router() {
  const token = localStorage.getItem('token');
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: '/', element: token ?<Navigate to="/dashboard/app" replace />: <Navigate to='/login' /> },
        { path: 'app', element: token ?<DashboardApp /> : <Navigate to='/login' />},
        { path: 'user', element: token ? <User /> : <Navigate to='/login' />},
        { path: 'realty', element: token ?<Products /> : <Navigate to='/login' />},
        { path: 'rent', element: token ?<Blog /> : <Navigate to='/login' />},
        { path: 'agent', element: token ?<Agent /> : <Navigate to='/login' />},
        {path:'recommend',element: token ?<Recom /> : <Navigate to='/login'/>},
        {path:'lord', element: token ?<Lord /> : <Navigate to='/login'/>},
        {path:'commercial', element: token ?<Commercial /> : <Navigate to='/login'/>},
        {path:'merchant', element: token ?<CommercialRent /> : <Navigate to='/login'/>},
        {path:'land', element: token ?<Land /> : <Navigate to='/login'/>}
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },
        // { path: 'register', element: <Register /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/dashboard" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },

    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
