import PropTypes from 'prop-types';
// material
import { Grid } from '@material-ui/core';
import ShopProductCard from './ProductCard';

// ----------------------------------------------------------------------



export default function ProductList({ user }) {
  return (
    <Grid container spacing={4}>
      {user.map((v,i) => (
        <Grid key={i}  item xs={12} sm={6} md={3}>
          <ShopProductCard v={v} i={i} />
        </Grid>
      ))}
    </Grid>
  );
}
