
const avatar =[
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1628714291/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/avatar/033-woman_kn6ise.png',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1628714291/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/avatar/034-woman_aferi0.png',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1628714291/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/avatar/011-woman_z5xunz.png',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1628714291/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/avatar/032-woman_hd61vf.png',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1628714291/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/avatar/025-man_at8bpo.png',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1628714291/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/avatar/027-woman_extb2p.png',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1628714291/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/avatar/031-woman_myk1yq.png',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1628714291/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/avatar/026-man_eilmif.png',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1628714291/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/avatar/030-woman_rhsctf.png',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1628714291/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/avatar/024-man_ef36bs.png',
    
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1628714291/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/avatar/029-woman_cqdif6.png',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1628714290/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/avatar/020-man_faojj4.png',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1628714290/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/avatar/018-man_sk2und.png',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1628714291/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/avatar/017-woman_sfjbyp.png',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1628714291/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/avatar/023-man_wgd42k.png', 
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1628714290/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/avatar/013-woman_jroplh.png',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1628714291/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/avatar/028-woman_usdiaf.png',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1628714291/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/avatar/022-man_dhckj6.png',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1628714290/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/avatar/019-man_aozvqp.png',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1628714290/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/avatar/016-woman_j7mdbj.png',

    'https://res.cloudinary.com/dlapk94rx/image/upload/v1628714291/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/avatar/021-man_u30rph.png',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1628714290/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/avatar/048-woman_lkd5ir.png',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1628714290/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/avatar/050-woman_iiynyu.png',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1628714290/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/avatar/014-woman_n9ytsz.png',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1628714290/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/avatar/012-woman_tsajo8.png',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1628714290/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/avatar/015-woman_pkuirz.png',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1628714290/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/avatar/047-woman_sc7fk8.png',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1628714290/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/avatar/009-man_yjm3cu.png',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1628714290/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/avatar/049-woman_fu9rpd.png',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1628714289/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/avatar/044-woman_hselv1.png',

    'https://res.cloudinary.com/dlapk94rx/image/upload/v1628714289/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/avatar/010-woman_dssavf.png',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1628714290/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/avatar/046-woman_miaz1x.png',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1628714289/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/avatar/045-woman_xglqyy.png',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1628714289/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/avatar/007-man_amwbyb.png',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1628714289/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/avatar/043-woman_kknv4p.png', 
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1628714289/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/avatar/008-man_wckqqk.png',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1628714289/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/avatar/040-man_bi6oxn.png',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1628714289/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/avatar/041-man_a4vb4s.png',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1628714289/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/avatar/042-man_et00nx.png',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1628714289/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/avatar/038-man_tji1ey.png',

    'https://res.cloudinary.com/dlapk94rx/image/upload/v1628714289/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/avatar/003-man_urgr5h.png',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1628714289/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/avatar/004-man_cw2r3o.png',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1628714289/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/avatar/005-man_wfrazn.png',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1628714289/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/avatar/039-man_kxj10v.png',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1628714288/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/avatar/001-man_t1ldsy.png', 
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1628714288/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/avatar/006-man_tdln2z.png',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1628714288/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/avatar/037-man_fan8dw.png',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1628714288/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/avatar/035-man_oocg8i.png',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1628714288/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/avatar/002-man_ci55on.png',
    'https://res.cloudinary.com/dlapk94rx/image/upload/v1628714288/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/avatar/036-man_fbgjvs.png',
]

const getAvatar =()=>avatar

export default getAvatar;