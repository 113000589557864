import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import houseFill from '@iconify/icons-bi/house-fill';
import keyFill from '@iconify/icons-bi/key-fill';
import baselineSupportAgent from '@iconify/icons-ic/baseline-support-agent';
import humanMaleBoard from '@iconify/icons-mdi/human-male-board';
import stakeholderIcon from '@iconify/icons-grommet-icons/stakeholder';
import buildingShop16Filled from '@iconify/icons-fluent/building-shop-16-filled';
import layerLandcover from '@iconify/icons-gis/layer-landcover';
import businessSharp from '@iconify/icons-ion/business-sharp';


const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon(pieChart2Fill)
  },
  {
    title: 'realty',
    path: '/dashboard/realty',
    icon: getIcon(houseFill)
  },
  {
    title:'commercial', 
    path:'/dashboard/commercial', 
    icon:getIcon(buildingShop16Filled)
  },
  {
    title: 'user',
    path: '/dashboard/user',
    icon: getIcon(peopleFill)
  },
 
  {
    title: 'rent',
    path: '/dashboard/rent',
    icon: getIcon(keyFill)
  },
  
  {
    title:'land',
    path:'/dashboard/land',
    icon: getIcon(layerLandcover)

  },
  {
    title:'commercialRent',
    path:'/dashboard/merchant',
    icon: getIcon(businessSharp)

  },
  {
    title:'agent', 
    path:'/dashboard/agent',
    icon: getIcon(baselineSupportAgent),
  },
  {
    title:'recommend',
    path:'/dashboard/recommend',
    icon: getIcon(humanMaleBoard)
  },
  {
    title:'lord', 
    path:'/dashboard/lord',
    icon: getIcon(stakeholderIcon)
  },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: getIcon(lockFill)
  // },
  // {
  //   title: 'register',
  //   path: '/register',
  //   icon: getIcon(personAddFill)
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: getIcon(alertTriangleFill)
  // }
];

export default sidebarConfig;
