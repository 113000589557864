import {useState,useEffect} from 'react'
import axios from 'axios'

import { Icon } from '@iconify/react';
// import bugFilled from '@iconify/icons-ant-design/bug-filled';
// material
import { alpha, styled } from '@material-ui/core/styles';
import { Card, Typography } from '@material-ui/core';
// utils
// import { fShortenNumber } from '../../../utils/formatNumber';
import baselineSupportAgent from '@iconify/icons-ic/baseline-support-agent';

import getApiUrl from '../../../utils/api';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.error.darker,
  backgroundColor: theme.palette.error.lighter
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.error.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.error.dark, 0)} 0%, ${alpha(
    theme.palette.error.dark,
    0.24
  )} 100%)`
}));

// ----------------------------------------------------------------------

// const TOTAL = 234;
const url = getApiUrl()
const PAGE =1
export default function AppBugReports() {

  const [agent,setAgent] = useState(0)
  const items = 10;


  useEffect(() => {

    const getAllAgent = async()=>{
      const result = await axios.get(`${url}/admin/all`,{params: {page:PAGE,items}})
      // console.log(result.data.data[0].total[0].total);
      setAgent(result.data.data[0].total[0].total)
    }
    getAllAgent()
},[PAGE,items]);

  return (
    <RootStyle>
      <IconWrapperStyle>
        <Icon icon={baselineSupportAgent} width={40} height={40} />
      </IconWrapperStyle>
      <Typography variant="h3"> {agent}</Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        Total Agents
      </Typography>
    </RootStyle>
  );
}
