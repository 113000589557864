import React,{useState,useEffect} from 'react'
import axios from 'axios'
import { GoogleMap, useJsApiLoader,Marker } from '@react-google-maps/api';
import { Icon } from '@iconify/react';

import plusFill from '@iconify/icons-eva/plus-fill';
import {
    Table,
    Stack,
    Button,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    TableHead,
    Modal,
    TextField,
    Grid,
    Paper, 
    Select,

} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import List from '../components/List/index'

import Page from '../components/Page';
  import getApiUrl from '../utils/api';
  import Scrollbar from '../components/Scrollbar';
import CommercialMoreMenu from '../components/_dashboard/commercial/index'

const TABLE_HEAD = [
  { id: 'address', label: 'Address', alignRight: false },
  { id: 'price', label: 'Price', alignRight: false },
  { id: 'zipCode', label: 'ZipCode', alignRight: false},
  {id:'city', label: 'City', alignRight: false},
  {id:'status', label: 'Status', alignRight: false},
  {id:'capRate', label: 'CapRate', alignRight: false},
  {id:'state', label: 'State', alignRight: false},
  {id:'date', label: 'Date', alignRight: false}
];

const url = getApiUrl();

const useStyles = makeStyles({
    row:{
        backgroundColor:'#81c784'
      },
      create:{
        position: 'absolute',
        width: '80%',
        left: '0',
        top: '',
        overflowY:'scroll'
      },
      paper:{
        width:'100%',
        padding:'10%',
        position: 'relative',
      },
      textField: {
        width: '100%',
      },
      check:{
          width: '100%',
          height: '100%',
      },
      containerStyle:{
        width:'100%',
        height:'50vh',
      },
});

export default function Commercial() {
    const classes = useStyles()
    const apiKey = process.env.REACT_APP_MAP_API
    const [page,setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [total,setTotal] = useState(0);
    const [isCreate,setIsCreate] = useState(false);
    const [commercial,setCommercial] = useState([]);
    const [value,setValue] = useState({})
    const [investmentHighlight,setInvestHighlight] = useState([])
    const [nearbyRestaurant,setNearByRestaurant] = useState([]);
    const [nearbyRetail,setNearByRetail] = useState([]);
    const [subway,setSubway] = useState([]);
    const [commuterRail,setCommuterRail] = useState([]);
    const [airport,setAirPort] = useState([]);
    const [point,setPoint] = useState({lat:40.75218399834244,lng:-73.82699301800103});
    const [imgUrl,setImgUrl] = useState([])
    const [previewLink,setPreviewLink] = useState([])
    

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey:apiKey
      })

    useEffect(()=>{
        const fetchCommercial = async()=>{
            const result = await axios.get(`${url}/commercial`,{params: {page:page+1,items:rowsPerPage}})
            setTotal(result.data.data[0].total[0].total)
            setCommercial(result.data.data[0].result)  
        }
        fetchCommercial();
    },[page,rowsPerPage])

   

    
      const handleCreateOpen = ()=>{
        setIsCreate(true)
      }
      const handleCreateClose = ()=>{
        setIsCreate(false)
      }

      const handleChangePage = (event, newPage) => {
        setPage(newPage);
      
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };

      const handleInputPrice = (e)=>{
          setValue({...value,price:e.target.value});
      }

      const handleInputAddress =(e)=>{
          setValue({...value,address:e.target.value});
      }

      const handleInputZipCode = (e)=>{
          setValue({...value,zipCode:e.target.value});
      }

      const handleInputCity = (e)=>{
          setValue({...value, city: e.target.value})
      }

      const handleInputLat=(e)=>{
          setValue({...value,lat: e.target.value})
      }

      const handleInputLng = (e)=>{
          setValue({...value,lng: e.target.value})
      }

      const handleInputDescription =(e)=>{
          setValue({...value, description: e.target.value})
      }

      const handleInputVirtualTour = (e)=>{
          setValue({...value,virtualTour: e.target.value})
      }

      const handleInputState = (e)=>{
          setValue({...value,state: e.target.value})
      }
      const handleInputCapRate = (e)=>{
          setValue({...value,capRate: e.target.value})
      }
      


      const handleInputSaleType = (e)=>{
        setValue({...value,saleType: e.target.value})
      }

      const handleInputSaleConditions = (e)=>{
          setValue({...value,saleConditions: e.target.value})
      }

      const handleInputPropertyTypes = (e)=>{
          setValue({...value,propertyTypes: e.target.value})
      }

      const handleInputBuildingSizes = (e)=>{
          setValue({...value,buildingSizes: e.target.value})
      }

      const handleInputBuildingClass = (e)=>{
          setValue({...value,buildingClass: e.target.value})
      }
      const handleInputYearBuild = (e)=>{
          setValue({...value,yearBuild: e.target.value})
      }

      const handleInputRenovated = (e)=>{
          setValue({...value,renovated: e.target.value})
      }
      const handleInputParking = (e)=>{
          setValue({...value,parking: e.target.value})
      }
      const handleInputZoning = (e)=>{
          setValue({...value,zoning: e.target.value})
      }
      const handleInputOpportunityZones = (e)=>{
          setValue({...value,opportunityZones: e.target.value})
      }

      const handleInputPricePerSF = (e)=>{
          setValue({...value,pricePerSF: e.target.value})
      }
      const handleInputNol = (e)=>{
          setValue({...value,nol: e.target.value})
      }
      const handleInputTenancy = (e)=>{
          setValue({...value,tenancy: e.target.value})
      }
      const handleInputBuildingHeight = (e)=>{
          setValue({...value,buildingHeight: e.target.value})
      }
      const handleInputBuildingFar = (e)=>{
          setValue({...value,buildingFar: e.target.value})
      }
      const handleInputLandAcres = (e)=>{
          setValue({...value,landAcres: e.target.value})
      }
      const handleInputAbout = (e)=>{
          setValue({...value,about: e.target.value})
      }
      
      const handlePropertyTax = (e)=>{
          setValue({...value,propertyTax: e.target.value})
      }
      const handlePropertySubtype = (e)=>{
          setValue({...value,propertySubtype: e.target.value})
      }
      const handleVideoUrl = (e)=>{
          setValue({...value,videoUrl: e.target.value})
      }

      const handleAgentPhone = (e)=>{
          setValue({...value,agentPhone: e.target.value})
      }
      const handleAgentEmail = (e)=>{
          setValue({...value,agentEmail: e.target.value})
      }

      const handleFileUploadImg = async(e)=>{
        const {files} = e.target
        const formData = new FormData();

        for(let i =0;i<files.length;i+=1){
          formData.append('photos',files[i])
          const link = URL.createObjectURL(files[i])
         const arr = previewLink;
         previewLink.push(link);
         setPreviewLink(arr)
        }
        // 别问 问就是他妈的批量上传的锅
      
        const uploadResult = await axios.post(`${url}/file`, formData)
        console.log(uploadResult);
        setImgUrl(uploadResult.data.data)
  }
console.log(value);
console.log(imgUrl);
  const handleFileUpload = async(e) =>{
    const file = e.target.files[0];
    const formData = new FormData();

      formData.append('file',file)

        const result = await axios.post(`${url}/file/file`, formData)
        setValue({...value,document:result.data.data})
  }

    const handleCheck = ()=>{
        setPoint({lat:value.lat,lng:value.lng});
    }

    const handleSubmit = async()=>{
        const {
            price,
            address,
            zipCode,
            city,
            lat,
            lng,
            description,
            virtualTour,
            state,
            capRate,
            document,
            saleType,
            saleConditions,
            propertyTypes,
            buildingSizes,
            buildingClass,
            yearBuild,
            renovated,
            parking,
            zoning,
            opportunityZones,
            pricePerSF,
            nol,
            tenancy,
            buildingHeight,
            buildingFar,
            landAcres,
            about,
            propertyTax,
            propertySubtype,
            videoUrl,
            agentPhone,
            agentEmail

        } = value;

        if(!price) alert('Missing price')
        if(!address) alert('Missing address')
        if(!zipCode) alert('Missing zip code')
        if(!city) alert('Missing city')
        if(!state) alert('Missing state')

        const images = imgUrl;


        const result = await axios.post(`${url}/commercial`,{
            price,
               address,
               zipCode,
               city,
               lat,
               lng,
               images,
               description,
               virtualTour,
               state,
               capRate,
               investmentHighlight,
               document,
               saleType,
               saleConditions,
               propertyTypes,
               buildingSizes,
               buildingClass,
               yearBuild,
               renovated,
               parking,
               zoning,
               opportunityZones,
               pricePerSF,
               nol,
               tenancy,
               buildingHeight,
               buildingFar,
               landAcres,
               about,
               nearbyRestaurant,
               nearbyRetail,
               subway,
               commuterRail,
               airport,
               propertyTax,
               propertySubtype,
               videoUrl,
               agentPhone,
               agentEmail
        })
        console.log(result);

        if(result.data.success){
            const arr =[...commercial]
            arr.unshift(result.data.data)
            setCommercial(arr)
            setTotal(total+1);
            setIsCreate(false)
            setValue({})
            setImgUrl([])
          }
          else{
            alert(`This is an error alert — ${result.data.data}!`)
          }

    }
    const handleDeleteCommercial = async(id,index)=>{
        const arr = [...commercial];
        arr.splice(index,1)
        setCommercial(arr)

        await axios.post(`${url}/commercial/delete`,{commercialId:id});
  }


      
    return (
        <Page title="Commercial | 全民地产">
            <Container>

                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Commercial
                    </Typography>
                    <Button
                    variant="contained"
                    to="#"
                    startIcon={<Icon icon={plusFill} />}
                    onClick={()=>handleCreateOpen()}
                    >
                        New Commercial
                    </Button>
                </Stack>


                <Scrollbar>
                    <TableContainer sx={{ minWidth: 800 }}>
                        <Table>
                            <TableHead>
                                <TableRow className={classes.row}>
                                {TABLE_HEAD.map((item,i)=>(
                    <TableCell key={i}>
                      {item.label}
                    </TableCell>
                  ))}
                  <TableCell>
                    More
                  </TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {commercial.map((row,i)=>(
                                    <TableRow hover
                                    key={i}
                                    tabIndex={-1}>
                                        <TableCell align="left">
                                            {row.address}
                                        </TableCell>
                                        <TableCell align="left">
                                            {row.price}
                                        </TableCell>
                                        <TableCell align="left">
                                            {row.zipCode}
                                        </TableCell>
                                        <TableCell align="left">
                                            {row.city}
                                        </TableCell>
                                        <TableCell align="left">
                                            {row.status}
                                        </TableCell>
                                        <TableCell align="left">
                                            {row.capRate}
                                        </TableCell>
                                        <TableCell align="left">
                                            {row.state}
                                        </TableCell>
                                        <TableCell align="left">
                                            {row.date}
                                        </TableCell>
                                        <TableCell>
                                            <CommercialMoreMenu
                                            handleDeleteCommercial={handleDeleteCommercial }  row={row} i={i}  commercial={commercial} setCommercial={setCommercial}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Scrollbar>





                <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
            </Container>

            <Modal
            className={classes.create}
            open={isCreate}
            onClose={()=>handleCreateClose()}
            >
                <Paper className={classes.paper}>
                    <Typography variant="h3">添加商业地产</Typography>
                    <Grid container spacing={4}> 

                    <Grid item xs={4}>
                    <TextField required className={classes.textField}   label='Price' type='number' defaultValue={value.price} onChange={(e)=>handleInputPrice(e)} />
                    </Grid>

                    <Grid item xs={8}>
                    <TextField required className={classes.textField}   label='Address' type='text' defaultValue={value.address} onChange={(e)=>handleInputAddress(e)} />
                    </Grid>

                    <Grid item xs={3}>
                    <TextField required className={classes.textField}   label='ZipCode' type='text' defaultValue={value.zipCode} onChange={(e)=>handleInputZipCode(e)} />
                    </Grid>

                    <Grid item xs={3}>
                    <TextField required className={classes.textField}   label='City' type='text' defaultValue={value.city} onChange={(e)=>handleInputCity(e)} />
                    </Grid>

                    <Grid item xs={3}>
                    <TextField required className={classes.textField}   label='State' type='text' defaultValue={value.state} onChange={(e)=>handleInputState(e)} />
                    </Grid>

                    <Grid item xs={3}>
                    <TextField  className={classes.textField}   label='CapRate' type='text' defaultValue={value.capRate} onChange={(e)=>handleInputCapRate(e)} />
                    </Grid>

                    <Grid item xs={6}>
                    <TextField  className={classes.textField}   label='SaleType' type='text' defaultValue={value.saleType} onChange={(e)=>handleInputSaleType(e)} />
                    </Grid>

                    <Grid item xs={6}>
                    <TextField  className={classes.textField}   label='SaleCondition' type='text' defaultValue={value.saleConditions} onChange={(e)=>handleInputSaleConditions(e)} />
                    </Grid>

                    <Grid item xs={6}>
                    <TextField  className={classes.textField}   label='PropertyTypes' type='text' defaultValue={value.propertyTypes} onChange={(e)=>handleInputPropertyTypes(e)} />
                    </Grid>
                    

                    <Grid item xs={3}>
                    <TextField  className={classes.textField}   label='YearBuild' type='number' defaultValue={value.yearBuild} onChange={(e)=>handleInputYearBuild(e)} />
                    </Grid>

                    <Grid item xs={3}>
                    <TextField  className={classes.textField}   label='Renovated' type='String' defaultValue={value.renovated} onChange={(e)=>handleInputRenovated(e)} />
                    </Grid>

                    <Grid item xs={6}>
                    <TextField  className={classes.textField}   label='BuildingSizes' type='String' defaultValue={value.buildingSizes} onChange={(e)=>handleInputBuildingSizes(e)} />
                    </Grid>

                    <Grid item xs={6}>
                    <TextField  className={classes.textField}   label='BuildingClass' type='String' defaultValue={value.buildingClass} onChange={(e)=>handleInputBuildingClass(e)} />
                    </Grid>

                    <Grid item xs={6}>
                    <TextField  className={classes.textField}   label='Parking' type='String' defaultValue={value.parking} onChange={(e)=>handleInputParking(e)} />
                    </Grid>

                    <Grid item xs={6}>
                    <TextField  className={classes.textField}   label='Zoning' type='String' defaultValue={value.zoning} onChange={(e)=>handleInputZoning(e)} />
                    </Grid>

                    <Grid item xs={6}>
                    <TextField  className={classes.textField}   label='OpportunityZones' type='String' defaultValue={value.opportunityZones} onChange={(e)=>handleInputOpportunityZones(e)} />
                    </Grid>

                    <Grid item xs={3}>
                    <TextField  className={classes.textField}   label='PricePerSF' type='String' defaultValue={value.pricePerSF} onChange={(e)=>handleInputPricePerSF(e)} />
                    </Grid>

                    <Grid item xs={3}>
                    <TextField  className={classes.textField}   label='Nol' type='String' defaultValue={value.nol} onChange={(e)=>handleInputNol(e)} />
                    </Grid>

                    <Grid item xs={4}>
                    <TextField  className={classes.textField}   label='Tenancy' type='String' defaultValue={value.tenancy} onChange={(e)=>handleInputTenancy(e)} />
                    </Grid>

                    <Grid item xs={4}>
                    <TextField  className={classes.textField}   label='BuildingHeight' type='String' defaultValue={value.buildingHeight} onChange={(e)=>handleInputBuildingHeight(e)} />
                    </Grid>

                    <Grid item xs={4}>
                    <TextField  className={classes.textField}   label='BuildingFar' type='String' defaultValue={value.buildingFar} onChange={(e)=>handleInputBuildingFar(e)} />
                    </Grid>

                    <Grid item xs={4}>
                    <TextField  className={classes.textField}   label='LandAcres' type='String' defaultValue={value.landAcres} onChange={(e)=>handleInputLandAcres(e)} />
                    </Grid>

                    <Grid item xs={4}>
                    <TextField  className={classes.textField}   label='PropertyTax' type='String' defaultValue={value.propertyTax} onChange={(e)=>handlePropertyTax(e)} />
                    </Grid>

                    <Grid item xs={4}>
                    <TextField  className={classes.textField}   label='PropertySubtype' type='String' defaultValue={value.propertySubtype} onChange={(e)=>handlePropertySubtype(e)} />
                    </Grid>
                    
                    <Grid item xs={12}>
                    <TextField multiline  className={classes.textField}   label='Description' type='String' defaultValue={value.description} onChange={(e)=>handleInputDescription(e)} />
                    </Grid>

                    <Grid item xs={6}>
                    <TextField   className={classes.textField}   label='AgentPhone' type='String' defaultValue={value.agentPhone} onChange={(e)=>handleAgentPhone(e)} />
                    </Grid>

                    <Grid item xs={6}>
                    <TextField  className={classes.textField}   label='AgentEmail' type='String' defaultValue={value.agentEmail} onChange={(e)=>handleAgentEmail(e)} />
                    </Grid>


                    <Grid item xs={12}>
                    <TextField multiline  className={classes.textField}   label='VirtualTour' type='String' defaultValue={value.virtualTour} onChange={(e)=>handleInputVirtualTour(e)} />
                    </Grid>

                    <Grid item xs={12}>
                    <TextField multiline  className={classes.textField}   label='About' type='String' defaultValue={value.about} onChange={(e)=>handleInputAbout(e)} />
                    </Grid>

                    <Grid item xs={12}>
                    <TextField multiline  className={classes.textField}   label='VideoUrl' type='String' defaultValue={value.videoUrl} onChange={(e)=>handleVideoUrl(e)} />
                    </Grid>
                    
                    <List list={investmentHighlight} label='InvestmentHighlight' setList={setInvestHighlight}  />

                    <List list={nearbyRestaurant} label='NearByRestaurant' setList={setNearByRestaurant}  />

                    <List list={nearbyRetail} label='NearByRetail' setList={setNearByRetail}  />

                    <List list={subway} label='Subway' setList={setSubway}  />

                    <List list={commuterRail} label='CommuterRail' setList={setCommuterRail}  />

                    <List list={airport} label='AirPort' setList={setAirPort}  />

                    <Grid item xs={5}>
                        <TextField  className={classes.textField}   label='latitude' type='String' defaultValue={value.lat} onChange={(e)=>handleInputLat(e)} />

                        </Grid>

                        <Grid item xs={5}>
                        <TextField  className={classes.textField}   label='longitude' type='String' defaultValue={value.lng} onChange={(e)=>handleInputLng(e)} />

                        </Grid>

                        <Grid item xs={2}>
                                    <Button variant='contained' className={classes.check} >Check</Button>
                        </Grid>

                        <Grid item xs={12}>
                     
                     {isLoaded&&<GoogleMap
                     mapContainerClassName={classes.containerStyle}
                     center={{lat:point.lat,lng:point.lng}}
                     zoom={15}
                    
                     >
                     <Marker
                     
                     position={point}
                     />
                     </GoogleMap>}
               </Grid>





                    <Grid item xs={12}>
                      <Typography variant="h3">上传图片</Typography>
                      </Grid>
                       <Grid item xs={3}> <input type="file" multiple onChange={(e)=>handleFileUploadImg(e)} /></Grid>
                     
                        {
                          previewLink&&imgUrl.map((v,i)=>(
                            <Grid item xs={3} key={i}><img className={classes.img} src={v} alt="" /></Grid>
                          ))
                             
                        }
                        <Grid item xs={12}>
                      <Typography variant="h3">上传文件</Typography>
                      </Grid>
                       <Grid item xs={3}> <input type="file"  onChange={(e)=>handleFileUpload(e)} />
                       </Grid>



                    <Grid item xs={12}>
                        <Button onClick={()=>handleSubmit()} className={classes.check} variant='contained' >Submit</Button>
                    </Grid>
                    </Grid> 
                </Paper>
            </Modal>








        </Page>
    )
}
