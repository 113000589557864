import PropTypes from 'prop-types';
import {useState} from 'react'
import axios from 'axios';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import { Link as RouterLink } from 'react-router-dom';
import parkingIcon from '@iconify/icons-fa-solid/parking';
import bxsDollarCircle from '@iconify/icons-bx/bxs-dollar-circle';
import { makeStyles } from "@material-ui/styles";
import { GoogleMap, useJsApiLoader,Marker } from '@react-google-maps/api';

// material
import {  styled  } from '@material-ui/core/styles';
import {  Link, Card, Grid, Avatar, Typography, CardContent,Box,Button,Stepper,Step,StepLabel,Modal,Paper,TextField} from '@material-ui/core';
// utils

//
import SvgIconStyle from '../../SvgIconStyle';
import getApiUrl from '../../../utils/api'

// ----------------------------------------------------------------------

const CardMediaStyle = styled('div')({
  position: 'relative',
  paddingTop: 'calc(100% * 3 / 4)'
});

const TitleStyle = styled(Link)({
  height: 44,
  overflow: 'hidden',
  WebkitLineClamp: 2,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical'
});

const AvatarStyle = styled(Avatar)(({ theme }) => ({
  zIndex: 9,
  width: 32,
  height: 32,
  position: 'absolute',
  left: theme.spacing(3),
  bottom: theme.spacing(-2)
}));

const InfoStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-end',
  marginTop: theme.spacing(3),
  color: theme.palette.text.disabled
}));

const CoverImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});

const useStyles = makeStyles((theme) => ({
  root: {
   backgroundColor:'#fff', 
   padding:'10%'
  },
  button: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(3),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  create:{
    position: 'absolute',
    width: '70%',
    left: '15%',
    top: '20%',
    overflowY:'scroll',

  },
  btn:{
    display:'flex', 
    justifyContent:'space-between',
    alignItems: 'center',
  },
  textField: {
    width: '100%',
  },
  containerStyle:{
    width:'100%',
    height:'50vh',
  },
}));

// ----------------------------------------------------------------------

BlogPostCard.propTypes = {
  post: PropTypes.object.isRequired,
  index: PropTypes.number
};

const RENT = 'https://res.cloudinary.com/dlapk94rx/image/upload/v1629408180/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/icons/rent-hanging-signal_onyxbc.png'

const PENDING = 'https://res.cloudinary.com/dlapk94rx/image/upload/v1629408180/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/icons/pending_velzu8.png'

const DONE = 'https://res.cloudinary.com/dlapk94rx/image/upload/v1629408180/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/icons/checked_blvtrm.png'

const DEFAULT = 'https://res.cloudinary.com/dlapk94rx/image/upload/v1626980354/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/ezgif.com-gif-maker_3_iflag6.webp'

const DELETE = 'https://res.cloudinary.com/dlapk94rx/image/upload/v1629742347/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/icons/bin-with-lid_apmvq5.png'

const DETAIL ='https://res.cloudinary.com/dlapk94rx/image/upload/v1629742347/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/icons/detail_uqze7i.png'


const url = getApiUrl();

function getSteps() {
  return ["available", "pending", "done"];
}


export default function BlogPostCard({ post, index,rent,setRent }) {
  const { address,zipCode,startDate,price,images,bathroom,bedroom,city,parking,views,status,_id,lat,lng,description,agentPhone,agentEmail,type} = post;
  console.log(post);
  const apiKey = process.env.REACT_APP_MAP_API
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey:apiKey
  })
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [isStatus,setIsStatus] = useState(false);
  const [isDetail,setDetail] = useState(false);
  const [isDelete,setIsDelete] = useState(false);
  const steps = getSteps();
  // const POST_INFO = [
  //   { number: comment, icon: messageCircleFill },
  //   { number: view, icon: eyeFill },
  //   { number: share, icon: shareFill }
  // ];
  const handleStatusOpen = ()=>{
      if(status==='available'){
        setActiveStep(0)
      }
      if(status==='pending'){
        setActiveStep(1)
      }
      if(status==='done'){
        setActiveStep(2)
      }
    setIsStatus(true)
  }
  const handleStatusClose = ()=>{
    setIsStatus(false)
  }

  const handleDetailOpen = ()=>{
    setDetail(true)
  }

  const handleDetailClose = ()=>{
    setDetail(false)
  }

  const handleDeleteOpen = ()=>{
    setIsDelete(true)
  }

  const handleDeleteClose = ()=>{
    setIsDelete(false)
  }

  const handleNext = async() => {
    await axios.post(`${url}/rent/status`,{
      rentId:_id,
      status:steps[activeStep+1]
    })
   
    const temp = [...rent];
    temp[index].status = steps[activeStep+1];
    setRent(temp)
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = async() => {
    await axios.post(`${url}/rent/status`,{
      rentId:_id,
      status:steps[activeStep-1]
    })
   
    const temp = [...rent];
    temp[index].status = steps[activeStep-1];
    setRent(temp)
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleDeleteBack =()=>{
    setIsDelete(false)
  }

  const handleDelete = async()=>{
      const result = await axios.post(`${url}/rent/delete`,{rentId:_id})
      if(result.data.success){
        const temp = [...rent]
        temp.splice(index, 1)
        setRent(temp);
        setIsDelete(false)
      }
      else{
        alert(result.data.e)
      }
  }

  return (
    <Grid item xs={12} sm={ 6} md={ 3}>
      <Card sx={{ position: 'relative' }}>
        <CardMediaStyle
         
        >
          <SvgIconStyle
            color="paper"
            src="/static/icons/shape-avatar.svg"
            sx={{
              width: 80,
              height: 36,
              zIndex: 9,
              bottom: -15,
              position: 'absolute',
              
            }}
          />
          {status ==='available'?<Button onClick={()=>handleStatusOpen()}><AvatarStyle
            
            src={RENT}
            
          /></Button>:''}
          {status ==='pending'?<Button onClick={()=>handleStatusOpen()}><AvatarStyle
            
            src={PENDING}
            
          /></Button>:''}
          {status ==='done'?<Button onClick={()=>handleStatusOpen()}><AvatarStyle
            
            src={DONE}
            
          /></Button>:''}

          <CoverImgStyle  src={images[0] ? images[0] : DEFAULT} />
        </CardMediaStyle>

        <CardContent
          sx={{
            pt: 4,
            
          }}
        >
          <Typography
            gutterBottom
            variant="caption"
            sx={{ color: 'text.disabled', display: 'block' }}
          >
            入住日期：{startDate.substring(0,10)}
          </Typography>

          <TitleStyle
            to="#"
            color="inherit"
            variant='subtitle2'
            underline="hover"
            component={RouterLink}
           
          >
            地址：{address}
          </TitleStyle>
          <TitleStyle
            to="#"
            color="inherit"
            variant='subtitle2'
            underline="hover"
            component={RouterLink}
           
          >
            {city} {zipCode}
          </TitleStyle>
          <TitleStyle
            to="#"
            color="inherit"
            variant='subtitle2'
            underline="hover"
            component={RouterLink}
           
          >
            {bedroom} bedroom {bathroom} bathroom
          </TitleStyle>

          <InfoStyle>
            {/* {POST_INFO.map((info, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  alignItems: 'left',
                  ml: index === 0 ? 0 : 1.5,
                  ...((latestPostLarge || latestPost) && {
                    color: 'grey.500'
                  })
                }}
              >
                <Box component={Icon} icon={info.icon} sx={{ width: 16, height: 16, mr: 0.5 }} />
                <Typography variant="caption">{fShortenNumber(info.number)}</Typography>
              </Box>
            ))} */}

            <Grid container spacing={3}> 

              <Grid item xs={6}>
                  <Box sx={{display:'flex', align:'left',justifyContent: 'center',alignItems:'center'}}>
                  <Box component={Icon} icon={bxsDollarCircle} sx={{ width: 30, height: 30, mr: 0}}/>
                  <Typography  variant='subtitle1'>{price}</Typography>
                  </Box>
                 
                </Grid>

                <Grid item xs={4}>
                  <Box sx={{display:'flex', align:'left',justifyContent: 'center',alignItems:'center'}}>
                  <Box component={Icon} icon={eyeFill} sx={{ width: 30, height: 30, mr: 0}}/>
                  <Typography variant='subtitle1'>{views}</Typography>
                  </Box>
                 
                </Grid>

              
               

            </Grid>
          </InfoStyle>
          { parking?
                  <Box sx={{display:'flex', align:'left', position:'absolute',top:'5px',right:'5px'}}>
                  <Box component={Icon} color="#218824" icon={parkingIcon} sx={{ width: 30, height: 30, mr: 0}}/>
                  </Box>
                 
               : ''}

                  <Button onClick={()=>handleDetailOpen()}>Detail</Button>
               
                  <Button onClick={()=>handleDeleteOpen()}  color='primary'>Delete</Button>

            
<Modal
className={classes.create}
open={isStatus}
onClose={()=>handleStatusClose()}
>
<Paper className={classes.root}>
  <Typography variant='h3' align='center'>当前状态:{steps[activeStep]}</Typography>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};

          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div>
        <div>
          <div>
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              className={classes.button}
            >
              Back
            </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              className={classes.button}
              disabled={activeStep === steps.length - 1}
            >
              {activeStep === steps.length - 1 ? "Finish" : "Next"}
            </Button>
          </div>
        </div>
      </div>
    </Paper>

</Modal>

<Modal
className={classes.create}
  open={isDetail}
  onClose={()=>handleDetailClose()}
>
  <Paper className={classes.root}>
        <Typography variant='h4'>Detail</Typography>

        <Grid container spacing={3}> 

              <Grid item xs={6}>

                    <TextField InputProps={{
            readOnly: true,
          }} className={classes.textField}   label='Address'  defaultValue={address}  />
                  </Grid>

                  <Grid  item xs={3}>
                    <TextField InputProps={{
            readOnly: true,
          }} label='ZipCode'  defaultValue={zipCode} 
                    
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <TextField InputProps={{
            readOnly: true,
          }}   label='City'  defaultValue={city} 
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <TextField InputProps={{
            readOnly: true,
          }} label='Bedroom' type='number' defaultValue={bedroom} />
                  </Grid>

                 

                  <Grid item xs={3}>
                    <TextField InputProps={{
            readOnly: true,
          }}   label='Bathroom'  defaultValue={bathroom} 
                     />
                  </Grid>

                 

                  <Grid item xs={3}>
                    <TextField InputProps={{
            readOnly: true,
          }}  label='Price'  defaultValue={price} 
                    
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <TextField InputProps={{
            readOnly: true,
          }} className={classes.textField}  label='Parking' type='text' defaultValue={parking} 
                   
                    />
                  </Grid>

                  <Grid item xs={8}>
                    <TextField InputProps={{
            readOnly: true,
          }}  className={classes.textField} multiline   label='Type'  defaultValue={type} 
                     />
                  </Grid>

                  <Grid item xs={4}>
                    <TextField InputProps={{
            readOnly: true,
          }}  className={classes.textField} multiline   label='Views'  defaultValue={views} 
                     />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField InputProps={{
            readOnly: true,
          }}  className={classes.textField} multiline   label='Description'  defaultValue={description} 
                     />
                  </Grid>

                 

                  <Grid item xs={5}>
                    <TextField InputProps={{
            readOnly: true,
          }} className={classes.textField}  label='Agent Phone' defaultValue={agentPhone} 
                  />
                  </Grid>

                  <Grid item xs={7}>
                    <TextField InputProps={{
            readOnly: true,
          }} className={classes.textField}  label='Agent Email'  defaultValue={agentEmail} 
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField InputProps={{
            readOnly: true,
          }} className={classes.textField}  label='StartDate' defaultValue={startDate.substring(0,10)} 
                  />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField InputProps={{
            readOnly: true,
          }} className={classes.textField}  label='latitude'  defaultValue={lat} 
                     />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField InputProps={{
            readOnly: true,
          }} className={classes.textField}  label='longitude' defaultValue={lng} 
                     />
                  </Grid>

                 

                  <Grid item xs={12}>
                     
                        {isLoaded&&<GoogleMap
                        mapContainerClassName={classes.containerStyle}
                        center={{lat:Number(lat),lng:Number(lng)}}
                        zoom={12}
                       
                        >
                      
                        <Marker
                          position={{lat:Number(lat), lng:Number(lng)}}
                        />
                        </GoogleMap>}
                  </Grid>

                  <Grid item xs={12}>
                      <Typography variant="h3">上传图片</Typography>
                      </Grid>
                      {
                          images&&images.map((v,i)=>(
                            <Grid item xs={3} key={i}><img className={classes.img} src={v} alt="" /></Grid>
                          ))
                             
                        }
                  </Grid>





        </Paper>
  </Modal>

  <Modal
className={classes.create}
  open={isDelete}
  onClose={()=>handleDeleteClose()}
>
  <Paper className={classes.root}>
        <Typography variant='h4' align='center'>Are you sure to Delete this Rent ?</Typography>

              
            <div className={classes.btn}>
              <Button variant='contained' onClick={()=>handleDeleteBack()}> Back</Button> 
          

          
              <Button onClick={()=>handleDelete()} variant='contained' color='secondary' > Delete</Button> 
              </div>
                  
       

  

        </Paper>
  </Modal>

        </CardContent>
      </Card>
    </Grid>
  );


}
