import {useState,useEffect} from 'react'
import axios from 'axios'

import { Icon } from '@iconify/react';
// import appleFilled from '@iconify/icons-ant-design/apple-filled';
// material
import { alpha, styled } from '@material-ui/core/styles';
import { Card, Typography } from '@material-ui/core';
// utils
import peopleFill from '@iconify/icons-eva/people-fill';
// import { fShortenNumber } from '../../../utils/formatNumber';

import getApiUrl from '../../../utils/api';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.warning.darker,
  backgroundColor: theme.palette.warning.main
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.warning.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.warning.dark, 0)} 0%, ${alpha(
    theme.palette.warning.dark,
    0.24
  )} 100%)`
}));

// ----------------------------------------------------------------------

// const TOTAL = 1352831;
const url = getApiUrl()
const PAGE =1
export default function AppNewUsers() {
  const [user,setUser] = useState(0)
  const items = 10
 
  useEffect(() => {
    const getAllUser = async()=>{
      const result = await axios.get(`${url}/user`,{params: {page:PAGE,items}})
  
      setUser(result.data.data[0].total[0].total)
  
    }

    getAllUser()
},[PAGE,items]);
  return (
    <RootStyle>
      <IconWrapperStyle>
        <Icon icon={peopleFill} width={40} height={40} />
      </IconWrapperStyle>
      <Typography variant="h3">{user}</Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        Users
      </Typography>
    </RootStyle>
  );
}
