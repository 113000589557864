import{useState,useEffect} from 'react'
import axios from 'axios'
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Grid, Button, Container, Stack, Typography,  TablePagination,Modal,Paper,TextField
} from '@material-ui/core';

// components
//
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { makeStyles } from '@material-ui/styles';
import { GoogleMap, useJsApiLoader,Marker } from '@react-google-maps/api';
import getApiUrl from '../utils/api';
import Page from '../components/Page';
import { BlogPostCard, BlogPostsSort, BlogPostsSearch } from '../components/_dashboard/blog';

// ----------------------------------------------------------------------
   


// ----------------------------------------------------------------------
const useStyles = makeStyles({
  row:{
      backgroundColor:'#81c784'
    },
    create:{
      position: 'absolute',
      width: '80%',
      left: '0',
      top: '',
      overflowY:'scroll'
    },
    paper:{
      width:'100%',
      padding:'5%',
      position: 'relative',
    },
    textField: {
      width: '100%',
    },
    check:{
        width: '100%',
        height: '100%',
    },
    containerStyle:{
      width:'100%',
      height:'50vh',
    },
    date:{
      marginTop:'20px',
      marginBottom:'30px'
    }
});
const url = getApiUrl();

const ICON = 'https://res.cloudinary.com/dlapk94rx/image/upload/v1629732370/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/icons/point_jwiq1i.png';

export default function Rent() {

  const classes = useStyles()
  const apiKey = process.env.REACT_APP_MAP_API

  const [rent,setRent] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [value,setValue] = useState({});

  const [total,setTotal] = useState(0);
  const [isCreate, setIsCreate] = useState(false);
  const [previewLink,setPreviewLink] = useState([])
  const [point,setPoint] = useState({lat:40.7522582,lng:-73.8269663})
  const [position,setPosition] = useState({})
  const [imgUrl,setImgUrl] = useState([])
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey:apiKey
  })

  useEffect(()=>{
    navigator.geolocation.getCurrentPosition((pos)=>{
      setPosition({lat:pos.coords.latitude,lng:pos.coords.longitude})
    })
  },[])

  const handleCreate = ()=>{
    setIsCreate(true)
  }

  const handleCloseCreate = ()=>{
    setIsCreate(false)
  }

  useEffect(() => {
    const getAllRent = async()=>{
      const result  =await axios.get(`${url}/rent`,{params: {page:page+1,items:rowsPerPage}})
      setTotal(result.data.data[0].total[0].total)
      setRent(result.data.data[0].result)      }
    getAllRent()

  },[page,rowsPerPage])


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const handleInputAddress =(e)=>{
    setValue({...value,address: e.target.value})
  }

  const handleInputPrice=(e)=>{
    setValue({...value,price: e.target.value})
  }

  const handleInputZip = (e)=>{
    setValue({...value,zipCode: e.target.value})
  }

  const handleInputBed = (e)=>{
    setValue({...value,bedroom: e.target.value})
  }
  const handleInputType=(e)=>{
    setValue({...value,type: e.target.value})
  }

  const handleInputBath = (e)=>{
    setValue({...value,bathroom: e.target.value})
  }

  const handleInputCity = (e)=>{
    setValue({...value, city: e.target.value})
  }

  const handleInputPark = (e)=>{
    setValue({...value,parking: e.target.value})
  }

  const handleInputDescription = (e)=>{
    setValue({...value, description: e.target.value})
  }

  const handleInputAgentPhone = (e)=>{
    setValue({...value,agentPhone: e.target.value})
  }

  const handleInputAgentEmail = (e)=>{
    setValue({...value,agentEmail: e.target.value})
  }

  const handleInputLat= (e)=>{
    setValue({...value,lat: Number(e.target.value)})
  }

  const handleInputLng = (e)=>{
    setValue({...value,lng: Number(e.target.value)})
  }

  const handleDate = (date)=>{
    setValue({...value,startDate:date})
  }

  const handleFileUploadImg = async(e)=>{
    
    // 别问 问就是他妈的批量上传的锅
    const {files} = e.target
    const formData = new FormData();

    for(let i =0;i<files.length;i+=1){
      formData.append('photos',files[i])
      const link = URL.createObjectURL(files[i])
     const arr = previewLink;
     previewLink.push(link);
     setPreviewLink(arr)
    }
    // 别问 问就是他妈的批量上传的锅
  
    const uploadResult = await axios.post(`${url}/file`, formData)
    console.log(uploadResult);
    setImgUrl(uploadResult.data.data)

}


const handleCheck = ()=>{
  setPoint({lat:Number(value.lat),lng:Number(value.lng),zoom:16})
}

const handleSubmit = async()=>{

  const { 
    price,
    bedroom,
    bathroom,
    type,
    description,
    address,
    zipCode,
    city,
    agentPhone,
    agentEmail,
    parking,
    lat,
    lng,
    startDate,
   
  } = value;
const   images = imgUrl;

  const result = await axios.post(`${url}/rent/create`,{
    price,
    bedroom,
    bathroom,
    type,
    description,
    address,
    zipCode,
    images,
    city,
    agentPhone,
    agentEmail,
    parking,
    lat,
    lng,
    startDate,
    
  })
  if(result.data.success){
      const temp = [...rent];
      temp.push(result.data.data)
      setRent(temp)
      setTotal(total+1)
      setIsCreate(false)
      setValue({})
      setPreviewLink([])
  }
  else{
    alert(result.data.e)

  }

}


  return (
    <Page title="Dashboard: Rent | 全民地产">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Rents
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="#"
            startIcon={<Icon icon={plusFill} />}
            onClick={()=>handleCreate()}
          >
            New Rent
          </Button>
        </Stack>

       

        <Grid container spacing={3}>
          {rent.map((post, index) => (
            <BlogPostCard key={index} post={post} index={index} rent={rent} setRent={setRent} />
          ))}
        </Grid>

        <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
      </Container>


      <Modal
         className={classes.create}
         open={isCreate}
         onClose={()=>handleCloseCreate()}
      >
            <Paper className={classes.paper}>
              <Typography variant="h4">
                Create New Rent
              </Typography>
              
             

              <Grid container spacing={3}> 

              <Grid item xs={6}>
                    <TextField required className={classes.textField}   label='Address' type='text' defaultValue={value.address} onChange={(e)=>handleInputAddress(e)} />
                  </Grid>

                  <Grid item xs={3}>
                    <TextField  required  label='ZipCode' type='number' defaultValue={value.zipCode} 
                    onChange={(e)=>handleInputZip(e)} 
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <TextField  required  label='City' type='text' defaultValue={value.city} 
                    onChange={(e)=>handleInputCity(e)}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <TextField required   label='Bedroom' type='number' defaultValue={value.bedroom}
                    onChange={(e)=>handleInputBed(e)} />
                  </Grid>

                 

                  <Grid item xs={3}>
                    <TextField required   label='Bathroom' type='number' defaultValue={value.bathroom} 
                    onChange={(e)=>handleInputBath(e)} />
                  </Grid>

                 

                  <Grid item xs={3}>
                    <TextField required   label='Price' type='number' defaultValue={value.price} 
                    onChange={(e)=>handleInputPrice(e)}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <TextField className={classes.textField}  label='Parking' type='text' defaultValue={value.parking} 
                    onChange={(e)=>handleInputPark(e)}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField  className={classes.textField} multiline   label='Type' type='text' defaultValue={value.type} 
                    onChange={(e)=>handleInputType(e)} />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField  className={classes.textField} multiline   label='Description' type='text' defaultValue={value.description} 
                    onChange={(e)=>handleInputDescription(e)} />
                  </Grid>

                 

                  <Grid item xs={5}>
                    <TextField className={classes.textField}  label='Agent Phone' type='text' defaultValue={value.agentPhone} 
                    onChange={(e)=>handleInputAgentPhone(e)} />
                  </Grid>

                  <Grid item xs={7}>
                    <TextField className={classes.textField}  label='Agent Email' type='email' defaultValue={value.agentEmail} 
                    onChange={(e)=>handleInputAgentEmail(e)} />
                  </Grid>

                  <Grid item xs={5}>
                    <TextField className={classes.textField}  label='latitude' type='number' defaultValue={value.lat} 
                    onChange={(e)=>handleInputLat(e)} />
                  </Grid>

                  <Grid item xs={5}>
                    <TextField className={classes.textField}  label='longitude' type='number' defaultValue={value.lng} 
                    onChange={(e)=>handleInputLng(e)} />
                  </Grid>

                  <Grid item xs={2}>
                    <Button className={classes.check}  onClick={()=>handleCheck()} variant='contained' color='secondary'>Check</Button>
                  </Grid>

                  <Grid item xs={12}>
                     
                        {isLoaded&&<GoogleMap
                        mapContainerClassName={classes.containerStyle}
                        center={{lat:point.lat,lng:point.lng}}
                        zoom={12}
                       
                        >
                       { position.lat&&<Marker
                        position={position}
                        icon={ICON}
                        />}
                        <Marker
                          position={{lat:Number(value.lat), lng:Number(value.lng)}}
                        />
                        </GoogleMap>}
                  </Grid>

                  <Grid item xs={12}>
                      <Typography variant="h3">上传图片</Typography>
                      </Grid>
                       <Grid item xs={3}> <input type="file" multiple onChange={(e)=>handleFileUploadImg(e)} /></Grid>
                     
                        {
                          previewLink&&previewLink.map((v,i)=>(
                            <Grid item xs={3} key={i}><img className={classes.img} src={v} alt="" /></Grid>
                          ))
                             
                        }
                      <Grid item xs={12}>
                        <Typography variant="h6">
                          Start Date
                        </Typography>
                        <DatePicker className={classes.date} selected={value.startDate ||  Date.now()} onChange={(date) => handleDate(date)} />
                        </Grid>
                    <Grid item xs={12}>
                      <Button variant='contained' color="primary" onClick={()=>handleSubmit()}>Submit</Button>
                    </Grid>

              </Grid>

              

            </Paper>


      </Modal>
    </Page>
  );
}
