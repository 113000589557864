import axios from 'axios'
import { Icon } from '@iconify/react';
import { useState,useEffect,} from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { GoogleMap, useJsApiLoader,Marker } from '@react-google-maps/api';

// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TableHead,
  Modal,
  TextField,
  Grid,
  Paper, 
  Select,
  MenuItem,
  InputLabel,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@material-ui/core';
// components
import { makeStyles } from '@material-ui/styles';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import {  UserMoreMenu } from '../components/_dashboard/user';

//
import getApiUrl from '../utils/api';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'address', label: 'Address', alignRight: false },
  { id: 'price', label: 'Price', alignRight: false },
  { id: 'realtyType', label: 'Type', alignRight: false },
  { id: 'bedroom', label: 'Bed', alignRight: false },
  { id: 'bathroom', label: 'Bath', alignRight: false },
  { id: 'zipCode', label: 'ZipCode', alignRight: false},
  {id:'city', label: 'City', alignRight: false},
  {id:'date', label: 'Date', alignRight: false},
  {id:'mls',label: 'MLS', alignRight: false},
];

// ----------------------------------------------------------------------

const url = getApiUrl();


const useStyles = makeStyles({
  create:{
    position: 'absolute',
    width: '80%',
    left: '0',
    top: '',
    overflowY:'scroll'
  },
  paper:{
    width:'100%',
    padding:'10%',
    position: 'relative',
  },
  textField: {
    width: '100%',
  },
  row:{
    backgroundColor:'#81c784'
  },
  btn:{
    width: '100%',
    height: '100%',
  },
  containerStyle:{
    width:'100%',
    height:'50vh',
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
  },
  imageList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  title: {
    color:'white',
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  delete:{
    width:'10%'
  },
  img:{ 
    widows:'100%'
  },
  Pending:{
    backgroundColor:'#ffb74d'
  },
  Sold:{
    backgroundColor:'#e57373'
  },
  selling:{
    backgroundColor:'#7986cb'
  }
})


export default function Realty() {
  const classes = useStyles()
  const apiKey = process.env.REACT_APP_MAP_API

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [total,setTotal] = useState(0);
  const [realty,setRealty] = useState([])
  const [isCreate,setIsCreate] = useState(false);
  const [value,setValue] = useState({})
  const [realtyType,setRealtyType] = useState('SingleFamily')

  const [point,setPoint] = useState({lat:-3.745,lng:-38.523,zoom:15})

  const [imgUrl,setImgUrl] = useState([])

  const [checkStatus,setCheckStatus] = useState('None')

  const [previewLink,setPreviewLink] = useState([])
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey:apiKey
  })
  const handleCheckBox = (e)=>{
    setPage(0)
    setCheckStatus(e.target.value)
  }

 
console.log(realty);
  useEffect(async()=>{
    const handleFetchRealtyByStatus =async(page,rowsPerPage,status)=>{
      const result = await axios.get(`${url}/realty/status`,{params:{page:page+1,items:rowsPerPage,status}})
      setTotal(result.data.data[0].total[0].total)
      setRealty(result.data.data[0].result)    
  }
  const getAllRealty = async()=>{
    const result = await axios.get(`${url}/realty/all`,{params: {page:page+1,items:rowsPerPage}})
      setTotal(result.data.data[0].total[0].total)
      setRealty(result.data.data[0].result)
}
    if(checkStatus==='None'){
      getAllRealty()

    } 
    else{
      handleFetchRealtyByStatus(page,rowsPerPage,checkStatus)
     
    }

  },[checkStatus,page,rowsPerPage])

  const handleDeleteRealty = async(id,index)=>{
        const arr = [...realty];
        arr.splice(index,1)
        setRealty(arr)

        await axios.post(`${url}/realty/delete`,{realtyId:id});
  }


  const handleChangePage = (event, newPage) => {
    // console.log(newPage);
    // const result = await axios.get(`${url}/realty/all`,{params: {page:newPage+1,items:rowsPerPage}})
    // setTotal(result.data.data[0].total[0].total)
    // setRealty(result.data.data[0].result)
    setPage(newPage);
  
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };



  const handleCreateOpen = ()=>{
    setIsCreate(true)
  }
  const handleCreateClose = ()=>{
    setIsCreate(false)
  }

  const handleSelectType = (e)=>{
    setRealtyType(e.target.value)
  }

  const handleInputAddress =(e)=>{
    setValue({...value,address: e.target.value})
  }

  const handleInputPrice=(e)=>{
    setValue({...value,price: e.target.value})
  }

  const handleInputZip = (e)=>{
    setValue({...value,zipCode: e.target.value})
  }

  const handleInputBed = (e)=>{
    setValue({...value,bedroom: e.target.value})
  }

  const handleInputBath = (e)=>{
    setValue({...value,bathroom: e.target.value})
  }

  const handleInputCity = (e)=>{
    setValue({...value, city: e.target.value})
  }

  const handleInputArea = (e)=>{
    setValue({...value, area: e.target.value})
  }

  const handleInputDescription = (e)=>{
    setValue({...value, description: e.target.value})
  }

  const handleInputVirtualTour = (e)=>{
    setValue({...value,virtualTour: e.target.value})
  }

  const handleInputYear = (e)=>{
    setValue({...value,yearBuild: e.target.value})
  }

  const handleInputPark = (e)=>{
    setValue({...value,parking: e.target.value})
  }

  const handleInputHoa = (e)=>{
    setValue({...value,hoa: e.target.value})
  }

  const handleInputTax = (e)=>{
    setValue({...value,tax: e.target.value})
  }

  const handleInputMls = (e)=>{
    setValue({...value,mls: e.target.value})
  }

  const handleInputAgentPhone = (e)=>{
    setValue({...value,agentPhone: e.target.value})
  }

  const handleInputAgentEmail = (e)=>{
    setValue({...value,agentEmail: e.target.value})
  }

  const handleInputLat= (e)=>{
    setValue({...value,lat: e.target.value})
  }

  const handleInputLng = (e)=>{
    setValue({...value,lng: e.target.value})
  }

  const handleCheck = ()=>{
    setPoint({lat:Number(value.lat),lng:Number(value.lng),zoom:16})
  }

  const handleFileUploadImg = async(e)=>{
        const {files} = e.target
        const formData = new FormData();

        for(let i =0;i<files.length;i+=1){
          formData.append('photos',files[i])
          const link = URL.createObjectURL(files[i])
         const arr = previewLink;
         previewLink.push(link);
         setPreviewLink(arr)
        }
        // 别问 问就是他妈的批量上传的锅
      
        const uploadResult = await axios.post(`${url}/file`, formData)
        setImgUrl(uploadResult.data.data)
  }

  // 还差一个图片上传和地图定位component 

 
  const handleSubmit = async() => {
    const {
      price,
      bedroom,
      bathroom,
      area,
      address,
      zipCode,
      city,
      description,
      virtualTour,
      yearBuild,
      parking,
      hoa,
      tax,
      mls,
      agentPhone,
      agentEmail
  } = value;
  const images = imgUrl;
  const {lat,lng} = point;


  if(!price)   alert('This is an error alert — Missing price!')

  if(!bedroom)   alert('This is an error alert — Missing bedroom!')

  if(!bathroom)  alert('This is an error alert — Missing bathroom!')

  if(!address)   alert('This is an error alert — Missing address!')

  if(!zipCode)  alert('This is an error alert — Missing zip code!')

  if(!city) alert('This is an error alert — Missing city!')

  if(!realtyType) alert('This is an error alert — Missing type!')


  const result =await axios.post(`${url}/realty/create`,{price,
    bedroom,
    bathroom,
    area,
    address,
    zipCode,
    city,
    images,
    description,
    virtualTour,
    lat,
    lng,
    realtyType,
    yearBuild,
    parking,
    hoa,
    tax,
    mls,
    agentPhone,
    agentEmail})

    if(result.data.success){
      const arr =[...realty]
      arr.unshift(result.data.data)
      setRealty(arr)
      setTotal(total+1);
      setIsCreate(false)
    }
    else{
      alert(`This is an error alert — ${result.data.data}!`)
    }
  }
  

  return (
  
    <Page title="Realty | 全民地产">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Realties
          </Typography>
          <Button
            variant="contained"
            to="#"
            startIcon={<Icon icon={plusFill} />}
            onClick={()=>handleCreateOpen()}
          >
            New Realty
          </Button>
        </Stack>
       
        <Card>
        
          <RadioGroup  row  value={checkStatus} onChange={(e)=>handleCheckBox(e)}>
        <FormControlLabel  value="Selling" control={<Radio   color='secondary'/>} label="Selling" />
        <FormControlLabel value="Pending" 
         control={<Radio  />} label="Pending" />
        <FormControlLabel value="Sold" control={<Radio   />} label="Sold" />
        <FormControlLabel value="None" control={<Radio className={classes.none}/>} label="None" />
      </RadioGroup>
         

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHead>
                  <TableRow className={classes.row}>
                  {TABLE_HEAD.map((item,i)=>(
                    <TableCell key={i}>
                      {item.label}
                    </TableCell>
                  ))}
                    <TableCell>
                    More
                  </TableCell>
                  </TableRow>
                 
                
                </TableHead>
                <TableBody>
                  {realty&&realty
                    .map((row,i) => {
                      const { address,price,realtyType,bedroom,bathroom,zipCode,mls,city,date, } = row;

                      return (
                        <TableRow
                          hover
                          key={i}
                          tabIndex={-1}
                          // className={status==='Pending'? classes.Pending:(status==='Sold'?classes.Sold:classes.selling)}
                        >
                         
                          <TableCell align='left'>
                                {address}
                          </TableCell>
                          <TableCell align="left">${price}</TableCell>
                          <TableCell align="left">{realtyType}</TableCell>
                          <TableCell align="left">{bedroom}</TableCell>
                          <TableCell align="left">
                            {bathroom}
                          </TableCell>
                          <TableCell align="left">
                            {zipCode}
                          </TableCell>
                          <TableCell align="left">
                            {city}
                          </TableCell>
                          <TableCell align="left">
                            {date}
                          </TableCell>
                          <TableCell align="left">
                            {mls}
                          </TableCell>
                          <TableCell align="right">
                            <UserMoreMenu handleDeleteRealty={handleDeleteRealty }  row={realty[i]} i={i}/>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                 
                </TableBody>
                
              </Table>
            </TableContainer>
          </Scrollbar>
        


          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <Modal 
        className={classes.create}
        open={isCreate}
        onClose={()=>handleCreateClose()}
        >
          <Paper className={classes.paper}>

          <Typography variant="h3">添加房产</Typography>
              <Grid container spacing={4}>

                  <Grid item xs={6}>
                    <TextField required className={classes.textField}   label='Address' type='text' defaultValue={value.address} onChange={(e)=>handleInputAddress(e)} />
                  </Grid>

                  <Grid item xs={3}>
                    <TextField  required  label='ZipCode' type='number' defaultValue={value.zipCode} 
                    onChange={(e)=>handleInputZip(e)} 
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <TextField  required  label='City' type='text' defaultValue={value.city} 
                    onChange={(e)=>handleInputCity(e)}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <TextField required   label='Bedroom' type='number' defaultValue={value.bedroom}
                    onChange={(e)=>handleInputBed(e)} />
                  </Grid>

                  <Grid item xs={3}>
                    <TextField required   label='Bathroom' type='number' defaultValue={value.bathroom} 
                    onChange={(e)=>handleInputBath(e)} />
                  </Grid>

                  <Grid item xs={3}>
                    <TextField   label='Area sf' type='text' defaultValue={value.area} 
                    onChange={(e)=>handleInputArea(e)} />
                  </Grid>

                  <Grid item xs={3}>
                    <TextField required   label='Price' type='number' defaultValue={value.price} 
                    onChange={(e)=>handleInputPrice(e)}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField required className={classes.textField} multiline   label='Description' type='text' defaultValue={value.description} 
                    onChange={(e)=>handleInputDescription(e)} />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField  className={classes.textField}    label='VirtualTour' type='text' defaultValue={value.virtualTour} 
                    onChange={(e)=>handleInputVirtualTour(e)} />
                  </Grid>

                  <Grid item xs={12}>
                    <InputLabel id='realty-type'>Realty Type</InputLabel>
                  <Select
                  labelId='realty-type'
                  required
                  className={classes.textField}
                      value={realtyType}
                      onChange={(e)=>handleSelectType(e)}
                    >
                      <MenuItem value='SingleFamily'>SingleFamily</MenuItem>
                      <MenuItem value='MultiFamily'>MultiFamily</MenuItem>
                      <MenuItem value='Condos'>Condos</MenuItem>
                    </Select>
                    </Grid>

                  <Grid item xs={2}>
                    <TextField  className={classes.textField} label='BuildYear' type='text' defaultValue={value.yearBuild} 
                    onChange={(e)=>handleInputYear(e)}
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <TextField className={classes.textField}  label='Parking' type='text' defaultValue={value.parking} 
                    onChange={(e)=>handleInputPark(e)}
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <TextField className={classes.textField}  label='HOA' type='text' defaultValue={value.hoa} 
                    onChange={(e)=>handleInputHoa(e)} />
                  </Grid>
                  
                  <Grid item xs={3}>
                    <TextField  className={classes.textField} label='Property Tax' type='text' defaultValue={value.tax} 
                    onChange={(e)=>handleInputTax(e)} />
                  </Grid>

                  <Grid item xs={3}>
                    <TextField  className={classes.textField} label='MLS Number' type='text' defaultValue={value.mls} 
                    onChange={(e)=>handleInputMls(e)} />
                  </Grid>

                  <Grid item xs={5}>
                    <TextField className={classes.textField}  label='Agent Phone' type='text' defaultValue={value.agentPhone} 
                    onChange={(e)=>handleInputAgentPhone(e)} />
                  </Grid>

                  <Grid item xs={7}>
                    <TextField className={classes.textField}  label='Agent Email' type='email' defaultValue={value.agentEmail} 
                    onChange={(e)=>handleInputAgentEmail(e)} />
                  </Grid>

                  <Grid item xs={5}>
                    <TextField className={classes.textField}  label='latitude' type='text' defaultValue={value.lat} 
                    onChange={(e)=>handleInputLat(e)} />
                  </Grid>

                  <Grid item xs={5}>
                    <TextField className={classes.textField}  label='longitude' type='text' defaultValue={value.lng} 
                    onChange={(e)=>handleInputLng(e)} />
                  </Grid>

                  <Grid item xs={2}>
                    <Button className={classes.btn}  onClick={()=>handleCheck()} variant='contained' color='secondary'>Check</Button>
                  </Grid>

                  <Grid item xs={12}>
                     
                        {isLoaded&&<GoogleMap
                        mapContainerClassName={classes.containerStyle}
                        center={{lat:point.lat,lng:point.lng}}
                        zoom={point.zoom}
                       
                        >
                        <Marker
                        
                        position={point}
                        />
                        </GoogleMap>}
                  </Grid>

                  <Grid item xs={12}>
                      <Typography variant="h3">上传图片</Typography>
                      </Grid>
                       <Grid item xs={3}> <input type="file" multiple onChange={(e)=>handleFileUploadImg(e)} /></Grid>
                     
                        {
                          previewLink&&imgUrl.map((v,i)=>(
                            <Grid item xs={3} key={i}><img className={classes.img} src={v} alt="" /></Grid>
                          ))
                             
                        }
                    <Grid item xs={12}>
                      <Button variant='contained' color="primary" onClick={()=>handleSubmit()}>Submit</Button>
                    </Grid>

              </Grid>
          </Paper>
        </Modal>
      
    </Page>

  );
}
