import { Icon } from '@iconify/react';
import { useRef, useState,useEffect} from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import { Link as RouterLink } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import outlineRealEstateAgent from '@iconify/icons-ic/outline-real-estate-agent';

// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText,Modal,Card,Typography,Button,Grid,Paper,TextField,Table,TableHead,TableRow,TableCell,TableBody,  InputLabel,Select,Stepper,Step,StepLabel
} from '@material-ui/core';
import bxDetail from '@iconify/icons-bx/bx-detail';
import { makeStyles } from '@material-ui/styles';
import { GoogleMap, useJsApiLoader,Marker } from '@react-google-maps/api';
import axios from 'axios';
import getApiUrl from '../../../utils/api'
// ----------------------------------------------------------------------
const useStyles = makeStyles({
    delete:{
      position: 'absolute',
      width: '50%',
      height: '300',
      left: '25%',
      top: '25%',
    },
    detail:{ 
      width: '80%',
      position:'absolute',
      top:'0',
      left:'0',
      overflowY:'scroll',
    },
    paper:{
      padding:'5%'
    },
    grid:{ 
      paddingTop:'5%'
    },
    input:{
      width:'100%',
    },
    containerStyle:{
      width:'100%',
      height:'50vh',
    },
  
    imgWrap:{
      position:'relative',
      overflowX:'scroll',
      width:'100%',
      display:'grid',
      height:'200px',
      gridTemplateColumns:'repeat(30,1fr)'
    },
    imgContainer:{
      border:'1px solid black',
      width:'200px',
    },
    img:{ 
      width:'100%',
      height:'auto',
    }, 
    row:{
      backgroundColor:'#81c784',
      width:'100%',
    },
    edit:{
      width: '80%',
      position:'absolute',
      top:'0',
      left:'0',
      overflowY:'scroll',
    },
    textField:{
      width:'100%',
    },
    btn:{
      width:'100%',
      height:'100%',
    },
    imgD:{
      width:'24px'
    },
    status:{
      alignItems:'center',
      display:'flex',
      justifyContent:'center',
      flexDirection:'column',
      padding:'2%'
    },
    statusModal:{
      position:'relative',
      width:'60%',
      left:'20%'
    },
    step:{
      padding:'2%'
    },
    none:{
      backgroundColor:'#81c784'
    }
   


})
const url = getApiUrl();
const steps =[ 'Selling','Pending','Sold'];


export default function UserMoreMenu({row,i,handleDeleteRealty}) {
  const classes = useStyles()
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isDelete,setIsDelete] = useState(false);
  const [isEdit,setIsEdit] = useState(false);
  const [isDetail,setIsDetail] = useState(false);
  const [isStatus,setIsStatus] = useState(false);
  const [value,setValue] = useState({})
  const [realtyType,setRealtyType] = useState(row.realtyType)
  const [previewLink,setPreviewLink] = useState([])
  const [activeStep,setActiveStep] = useState(0)
  const [id,setId] = useState('')
  const apiKey = process.env.REACT_APP_MAP_API
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey:apiKey
  })
  const [likeUser,setLikeUser]= useState([])

  useEffect(()=>{
      const index = steps.indexOf(row.status)
      setActiveStep(index)
      setId(row._id)
  },[row])
  const fetchUser = async()=>{
    const result = await axios.get(`${url}/realty/like`,{params:{realtyId: id}})
    setLikeUser(result.data.data)
  }
  const handleStatusOpen = ()=>{
    setIsStatus(true)
  }
  const handleStatusClose=()=>{
    setIsStatus(false)
  }

  
  const EPOCH_CONVERT = 1000;

const CONVERT_DAY_MULTIPLE = 86400;
// const CONVERT_WEEK_MULTIPLE = 604800;
// const CONVERT_MONTH_MULTIPLE = 2629743;

const ISODate = new Date();
	const today = Math.floor(ISODate.getTime() / EPOCH_CONVERT);
const convertNumber = (today-row.epoch)/CONVERT_DAY_MULTIPLE;
  const handleDeleteOpen = ()=>{
    setIsDelete(true)
    setId(row._id)
    setValue(row)

  }
  const handleDeleteClose = ()=>{
    setIsDelete(false)
  }
  const handleEditOpen = ()=>{
    setIsEdit(true)
    setId(row._id)
    setValue(row)
    setPreviewLink(row.images)
    // console.log(row);
    // console.log(previewLink);

  }
  const handleEditClose = ()=>{
    setIsEdit(false)
  }
  const handleDetailOpen = ()=>{
     fetchUser()
     setId(row._id)
     setPreviewLink(row.images)

    //  console.log(row);
    //  console.log(previewLink);

    setIsDetail(true)

  }
  const handleDetailClose = ()=>{
    setIsDetail(false)
  }
  const handleDeleteModalClose = ()=>{
    setIsDelete(false)
    setIsOpen(false)
  }
  const handleDeleteSure = (id,i)=>{

    handleDeleteRealty(id,i)


    setIsDelete(false)
    setIsOpen(false)
  }

  

 

  const handleSelectType = (e)=>{
    setValue({...value,realtyType: e.target.value})
  }

  const handleInputAddress =(e)=>{
    setValue({...value,address: e.target.value})
  }

  const handleInputPrice=(e)=>{
    setValue({...value,price: e.target.value})
  }

  const handleInputZip = (e)=>{
    setValue({...value,zipCode: e.target.value})
  }

  const handleInputBed = (e)=>{
    setValue({...value,bedroom: e.target.value})
  }

  const handleInputBath = (e)=>{
    setValue({...value,bathroom: e.target.value})
  }

  const handleInputCity = (e)=>{
    setValue({...value, city: e.target.value})
  }

  const handleInputArea = (e)=>{
    setValue({...value, area: e.target.value})
  }

  const handleInputDescription = (e)=>{
    setValue({...value, description: e.target.value})
  }

  const handleInputVirtualTour = (e)=>{
    setValue({...value,virtualTour: e.target.value})
  }

  const handleInputYear = (e)=>{
    setValue({...value,yearBuild: e.target.value})
  }

  const handleInputPark = (e)=>{
    setValue({...value,parking: e.target.value})
  }

  const handleInputHoa = (e)=>{
    setValue({...value,hoa: e.target.value})
  }

  const handleInputTax = (e)=>{
    setValue({...value,tax: e.target.value})
  }

  const handleInputMls = (e)=>{
    setValue({...value,mls: e.target.value})
  }

  const handleInputAgentPhone = (e)=>{
    setValue({...value,agentPhone: e.target.value})
  }

  const handleInputAgentEmail = (e)=>{
    setValue({...value,agentEmail: e.target.value})
  }

  const handleInputLat= (e)=>{
    setValue({...value,lat: parseFloat(e.target.value)})
  }

  const handleInputLng = (e)=>{
    setValue({...value,lng: parseFloat(e.target.value)})
  }
  const [point,setPoint]= useState({lat:row.lat,lng:row.lng})
  const handleCheck = ()=>{
    setPoint({lat:parseFloat(value.lat),lng:parseFloat(value.lng)})
  }
  const handleFileUploadImg = async(e)=>{
    
    // 别问 问就是他妈的批量上传的锅
    const {files} = e.target;
    const formData = new FormData();
    const arr = [...previewLink];

        for(let i =0;i<files.length;i+=1){
          formData.append('photos',files[i])
          const link = URL.createObjectURL(files[i])
         arr.push(link);
        }
        setPreviewLink(arr)

        const uploadResult = await axios.post(`${url}/file`, formData)
        const arr2 =uploadResult.data.data;
       
        const temp =previewLink.concat(arr2)
        setPreviewLink(temp)

}
  const handleSubmit = async()=>{
    const {
      price,
      bedroom,
      bathroom,
      area,
      address,
      zipCode,
      city,
      description,
      virtualTour,
      yearBuild,
      parking,
      hoa,
      tax,
      mls,
      agentPhone,
      agentEmail,
      lng,
      lat,
      realtyType
  } = value;

  const images = previewLink;
  if(!price)   alert('This is an error alert — Missing price!')

  if(!bedroom)   alert('This is an error alert — Missing bedroom!')

  if(!bathroom)  alert('This is an error alert — Missing bathroom!')

  if(!address)   alert('This is an error alert — Missing address!')

  if(!zipCode)  alert('This is an error alert — Missing zip code!')

  if(!city) alert('This is an error alert — Missing city!')

  if(!realtyType) alert('This is an error alert — Missing type!')

    const result = await axios.post(`${url}/realty/update`,{
      price,
      realtyId:id,
      bedroom,
      bathroom,
      area,
      address,
      zipCode,
      city,
      description,
      virtualTour,
      yearBuild,
      parking,
      hoa,
      tax,
      mls,
      agentPhone,
      agentEmail,
      lng,
      lat,
      images,
      realtyType
    })
    if(result.data.success){
      
      setIsEdit(false)
      setIsOpen(false)
    }
    else{
      alert(`This is an error alert — ${result.data.data}!`)
    }

  }

  const handleDeleteImg = async(i)=>{

    const arr = [...previewLink];
    arr.splice(i, 1);
    setPreviewLink(arr)
  }
 

  const handleUpdateStatus = async(id,status)=>{
      const result = await axios.post(`${url}/realty/status`,{realtyId:id,status})
      return result;
  }

  const handleBack =async()=>{
    if(activeStep === 1){
      const result = await handleUpdateStatus(id,'Selling');
      if(result.data.success){

        setActiveStep((prevActiveStep) => prevActiveStep - 1);
      }
    }
     if(activeStep ===2){
      const result = await handleUpdateStatus(id,'Pending');
      if(result.data.success){

        setActiveStep((prevActiveStep) => prevActiveStep - 1);
      }
    }

  }
  const handleNext =async()=>{
    if(activeStep === 0){
      const result = await handleUpdateStatus(id,'Pending');
      if(result.data.success){

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
     if(activeStep ===1){
      const result = await handleUpdateStatus(id,'Sold');
      if(result.data.success){

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
    
    

  }

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem sx={{ color: 'text.secondary' }} onClick={()=>handleDeleteOpen()}>
          <ListItemIcon>
            <Icon icon={trash2Outline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        <MenuItem component={RouterLink} to="#" onClick={()=>handleEditOpen()} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        <MenuItem component={RouterLink} to="#" onClick={()=>handleDetailOpen()} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Icon icon={bxDetail} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Detail" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        <MenuItem component={RouterLink} to="#" onClick={()=>handleStatusOpen()} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Icon icon={outlineRealEstateAgent} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Status" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

      </Menu>
        
      <Modal
        className={classes.delete}
        open={isDelete} 
        onClose={()=>handleDeleteClose()}
      >
        <Card>
        <Typography align="center">Are you sure you want delete this?</Typography>
        <Grid container >
       
          <Grid item xs={6}>
            <Paper align="center">
              <Button onClick={()=>handleDeleteSure(row._id,i)} >Delete</Button>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper align="center">
            <Button  onClick={()=>handleDeleteModalClose()}>Cancel</Button>
            </Paper>
           
          </Grid>
        
        </Grid>
       
      
        </Card>
      </Modal>

      <Modal
      open={isDetail}
      onClose={()=>handleDetailClose()}
      className={classes.detail}
      >
        <Paper className={classes.paper} >

            <Typography variant="h3">房产详情</Typography>


            <Grid className={classes.grid} container spacing={4} >

                <Grid item xs={12}>
                    <TextField label='Address' defaultValue={row.address} InputProps={{readOnly:true}}/>
                </Grid>

                <Grid item xs={2}>
                    <TextField label='ZipCode' defaultValue={row.zipCode} InputProps={{readOnly:true}}/>
                </Grid>

                <Grid item xs={2}>
                    <TextField label='City' defaultValue={row.city} InputProps={{readOnly:true}}/>
                </Grid>

                <Grid item xs={4}>
                    <TextField className={classes.input}  label='Price' defaultValue={row.price} InputProps={{readOnly:true}}/>
                </Grid>

                <Grid item xs={2}>
                    <TextField label='Bedroom' defaultValue={row.bedroom} InputProps={{readOnly:true}}/>
                </Grid>

                <Grid item xs={2}>
                    <TextField label='Bathroom' defaultValue={row.bathroom} InputProps={{readOnly:true}}/>
                </Grid>

                <Grid item xs={2}>
                    <TextField label='Area/sf' defaultValue={row.area} InputProps={{readOnly:true}}/>
                </Grid>

                <Grid item xs={2}>
                    <TextField label='Status' defaultValue={row.status} InputProps={{readOnly:true}}/>
                </Grid>

                <Grid item xs={2}>
                    <TextField label='View' defaultValue={row.views} InputProps={{readOnly:true}}/>
                </Grid>

                <Grid item xs={4}>
                    <TextField className={classes.input}  label='RealtyType' defaultValue={row.realtyType} InputProps={{readOnly:true}}/>
                </Grid>

                <Grid item xs={2}>
                    <TextField label='天前' defaultValue={Math.ceil(convertNumber) } InputProps={{readOnly:true}}/>
                </Grid>

                <Grid item xs={12}>
                    <TextField label='天前' defaultValue={row.mls } InputProps={{readOnly:true}}/>
                </Grid>

                <Grid item xs={4}>
                    <TextField className={classes.input}  label='发布日期' defaultValue={row.date} InputProps={{readOnly:true}}/>
                </Grid>

                <Grid item xs={3}>
                    <TextField className={classes.input}  label='经纪电话' defaultValue={row.agentPhone} InputProps={{readOnly:true}}/>
                </Grid>

                <Grid item xs={5}>
                    <TextField className={classes.input}  label='经纪邮箱' defaultValue={row.agentEmail} InputProps={{readOnly:true}}/>
                </Grid>

               

                <Grid item xs={12}>
                    <TextField className={classes.input} label='Description' defaultValue={row.description} multiline InputProps={{readOnly:true}}/>
                </Grid>

                <Grid item xs={3}>
                    <TextField label='BuildYear' defaultValue={row.yearBuild} InputProps={{readOnly:true}}/>
                </Grid>

                <Grid item xs={3}>
                    <TextField label='Parking' defaultValue={row.parking} InputProps={{readOnly:true}}/>
                </Grid>

                <Grid item xs={3}>
                    <TextField label='Hoa' defaultValue={row.hoa} InputProps={{readOnly:true}}/>
                </Grid>

                <Grid item xs={3}>
                    <TextField label='Tax' defaultValue={row.tax} InputProps={{readOnly:true}}/>
                </Grid>

                <Grid item xs={12}>
                    <TextField className={classes.input} label='VirtualTour' defaultValue={row.virtualTour} InputProps={{readOnly:true}}/>
                </Grid>


                <Grid item xs={6}>
                    <TextField className={classes.input}  label='latitude' defaultValue={row.lat} InputProps={{readOnly:true}}/>
                </Grid>

                <Grid item xs={6}>
                    <TextField className={classes.input}  label='longitude' defaultValue={row.lng} InputProps={{readOnly:true}}/>
                </Grid>
                <Grid item xs={12}>
                     
                        {isLoaded&&<GoogleMap
                        mapContainerClassName={classes.containerStyle}
                        center={{lat:Number(row.lat),lng:Number(row.lng)}}
                        zoom={15}
                       
                        >
                        <Marker
                        position={{lat:Number(row.lat),lng:Number(row.lng)}}
                        />
                        </GoogleMap>}
                  </Grid>
                  <Grid item xs={12}>

                  <Typography variant="h3">房产图片</Typography>
                  </Grid>
                  <div className={classes.imgWrap}>
                  {
                    previewLink ? previewLink.map((v,i)=>(
                      <div className={classes.imgContainer}  key={i}>
                        <img className={classes.img} src={v} alt="" />
                      </div>
                    )) :   <Grid item xs={12}>
                    <Typography variant="h3">暂无图片</Typography>
                    </Grid>
                  }
                  </div>
                  <Grid item xs={12}>

                  <Typography variant="h3">感兴趣的用户</Typography>
                  </Grid>
                    <Table>

                      <TableHead>
                        <TableRow className={classes.row}>
                          <TableCell>
                            <Typography>Name</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>Phone</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>Email</Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                       
                        {
                    likeUser&&likeUser.map((v,i)=>(
                      <TableRow key={i}>
                        <TableCell align='left'>
                          {v.userName}
                        </TableCell>
                        <TableCell align='left'>
                        {v.phone}
                      </TableCell>
                      <TableCell align='left'>
                      {v.email}
                    </TableCell>
                    </TableRow>

                    ))
                  }

                      </TableBody>

                    </Table>

                 
                  

            </Grid>

        </Paper>

      </Modal>

      <Modal
      className={classes.edit}
      open={isEdit}
      onClose={()=>handleEditClose()}
      >
        
        <Paper className={classes.paper}>

<Typography variant="h3">更改房产</Typography>  
    <Grid container spacing={4}>

        <Grid item xs={6}>
          <TextField required className={classes.textField}   label='Address' type='text' defaultValue={value.address} onChange={(e)=>handleInputAddress(e)} />
        </Grid>

        <Grid item xs={3}>
          <TextField  required  label='ZipCode' type='number' defaultValue={value.zipCode} 
          onChange={(e)=>handleInputZip(e)} 
          />
        </Grid>

        <Grid item xs={3}>
          <TextField  required  label='City' type='text' defaultValue={value.city} 
          onChange={(e)=>handleInputCity(e)}
          />
        </Grid>

        <Grid item xs={3}>
          <TextField required   label='Bedroom' type='number' defaultValue={value.bedroom}
          onChange={(e)=>handleInputBed(e)} />
        </Grid>

        <Grid item xs={3}>
          <TextField required   label='Bathroom' type='number' defaultValue={value.bathroom} 
          onChange={(e)=>handleInputBath(e)} />
        </Grid>

        <Grid item xs={3}>
          <TextField   label='Area sf' type='text' defaultValue={value.area} 
          onChange={(e)=>handleInputArea(e)} />
        </Grid>

        <Grid item xs={3}>
          <TextField required   label='Price' type='number' defaultValue={value.price} 
          onChange={(e)=>handleInputPrice(e)}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField required className={classes.textField} multiline   label='Description' type='text' defaultValue={value.description} 
          onChange={(e)=>handleInputDescription(e)} />
        </Grid>

        <Grid item xs={12}>
          <TextField  className={classes.textField}    label='VirtualTour' type='text' defaultValue={value.virtualTour} 
          onChange={(e)=>handleInputVirtualTour(e)} />
        </Grid>

        <Grid item xs={12}>
          <InputLabel id='realty-type'>Realty Type</InputLabel>
        <Select
        labelId='realty-type'
        required
        className={classes.textField}
            value={realtyType}
            onChange={(e)=>handleSelectType(e)}
          >
            <MenuItem value='SingleFamily'>SingleFamily</MenuItem>
            <MenuItem value='MultiFamily'>MultiFamily</MenuItem>
            <MenuItem value='Condos'>Condos</MenuItem>
          </Select>
          </Grid>

        <Grid item xs={2}>
          <TextField  className={classes.textField} label='BuildYear' type='text' defaultValue={value.yearBuild} 
          onChange={(e)=>handleInputYear(e)}
          />
        </Grid>

        <Grid item xs={2}>
          <TextField className={classes.textField}  label='Parking' type='text' defaultValue={value.parking} 
          onChange={(e)=>handleInputPark(e)}
          />
        </Grid>

        <Grid item xs={2}>
          <TextField className={classes.textField}  label='HOA' type='text' defaultValue={value.hoa} 
          onChange={(e)=>handleInputHoa(e)} />
        </Grid>
        
        <Grid item xs={3}>
          <TextField  className={classes.textField} label='Property Tax' type='text' defaultValue={value.tax} 
          onChange={(e)=>handleInputTax(e)} />
        </Grid>

        <Grid item xs={3}>
          <TextField  className={classes.textField} label='MLS Number' type='text' defaultValue={value.mls} 
          onChange={(e)=>handleInputMls(e)} />
        </Grid>

        <Grid item xs={5}>
          <TextField className={classes.textField}  label='Agent Phone' type='text' defaultValue={value.agentPhone} 
          onChange={(e)=>handleInputAgentPhone(e)} />
        </Grid>

        <Grid item xs={7}>
          <TextField className={classes.textField}  label='Agent Email' type='email' defaultValue={value.agentEmail} 
          onChange={(e)=>handleInputAgentEmail(e)} />
        </Grid>

        <Grid item xs={5}>
          <TextField className={classes.textField}  label='latitude' type='text' defaultValue={value.lat} 
          onChange={(e)=>handleInputLat(e)} />
        </Grid>

        <Grid item xs={5}>
          <TextField className={classes.textField}  label='longitude' type='text' defaultValue={value.lng} 
          onChange={(e)=>handleInputLng(e)} />
        </Grid>

        <Grid item xs={2}>
          <Button className={classes.btn}  onClick={()=>handleCheck()} variant='contained' color='secondary'>Check</Button>
        </Grid>

        <Grid item xs={12}>
           
              {isLoaded&&<GoogleMap
              mapContainerClassName={classes.containerStyle}
              center={point}
              zoom={15}
             
              >
              <Marker
              position={point}
              />
              </GoogleMap>}
        </Grid>

        <Grid item xs={12}>
            <Typography variant="h3">上传图片</Typography>
            </Grid>
             <Grid item xs={3}> <input type="file" multiple onChange={(e)=>handleFileUploadImg(e)} /></Grid>
           
              {
                previewLink&&previewLink.map((v,i)=>(
                  <Grid item xs={3} key={i}><img className={classes.img} src={v} alt="" />
                  <Button onClick={()=>handleDeleteImg(i)}>

                  <img className={classes.imgD}src="https://res.cloudinary.com/dlapk94rx/image/upload/v1628537791/%E9%87%91%E5%AD%97%E5%A1%94%E7%BD%AE%E4%B8%9A/remove_ebe72q.png" alt=""  />
                  </Button>
                 
                  </Grid>
                ))
                   
              }
          <Grid item xs={12}>
            <Button className={classes.btn} variant='contained' color="primary" onClick={()=>handleSubmit()}>Submit</Button>
          </Grid>

    </Grid>
</Paper>

      </Modal>     

      <Modal 
      open={isStatus}
      onClose={()=>handleStatusClose()}
      className={classes.statusModal}
      >
              
       
          <Paper className={classes.step}>
          <Stepper activeStep={activeStep} alternativeLabel>
              {
                steps.map((v,i)=>(
                  <Step key={i}>
                    <StepLabel>{v}</StepLabel>
                  </Step>
                ))
              }
        </Stepper>
        <div>
       
          <div className={classes.status}>
            <Typography variant='h3' >Current Status:{steps[activeStep]}</Typography>
            <div>
              <Button
                disabled={activeStep === 0}
                onClick={()=>handleBack()}
                className={classes.backButton}
              >
                Back
              </Button>
              <Button disabled={activeStep ===steps.length-1}variant="contained" color="primary" onClick={()=>handleNext()}>
                {activeStep === steps.length-1  ? 'Done' : 'Next'}
              </Button>
            </div>
          </div>
        
      </div>
      </Paper>
        
      </Modal>    

    </>
  );
}
