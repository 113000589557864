import {useState,useEffect} from 'react'
import axios from 'axios'

import { Icon } from '@iconify/react';
// import appleFilled from '@iconify/icons-ant-design/apple-filled';
// material
import { alpha, styled } from '@material-ui/core/styles';
import { Card, Typography } from '@material-ui/core';
// utils
import humanMaleBoard from '@iconify/icons-mdi/human-male-board';
// import { fShortenNumber } from '../../../utils/formatNumber';

import getApiUrl from '../../../utils/api';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.info.darker,
  backgroundColor: theme.palette.info.lighter
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.info.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.info.dark, 0)} 0%, ${alpha(
    theme.palette.info.dark,
    0.24
  )} 100%)`
}));

// ----------------------------------------------------------------------

// const TOTAL = 1352831;
const url = getApiUrl()
const PAGE =1
export default function AppRecommend() {
  const [recommend,setRecommend] = useState(0)
  const items = 10

  
  useEffect(() => {
    const getAllRecommend = async()=>{
      const result = await axios.get(`${url}/recommend`,{params: {page:PAGE,items}})
      // console.log(result.data.data[0].total[0].total);
      setRecommend(result.data.data[0].total[0].total)
    }
    getAllRecommend()
},[PAGE,items]);
  return (
    <RootStyle>
      <IconWrapperStyle>
        <Icon icon={humanMaleBoard} width={40} height={40} />
      </IconWrapperStyle>
      <Typography variant="h3">{recommend}</Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        Total Recommends
      </Typography>
    </RootStyle>
  );
}
