import {useState} from 'react'
import {Button,TextField,Grid,Paper,Typography} from '@material-ui/core'
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import minusFill from '@iconify/icons-eva/minus-fill';
import { makeStyles } from '@material-ui/styles';



const useStyles = makeStyles({
    
      paper:{
        width:'100%',
        padding:'5%',
        position: 'relative',
      },
      textField: {
        width: '100%',
      },
      btn:{
          height: '100%',
          width: '100%',
      },
      grid:{
          paddingTop:'20px'
      },
      btn1:{
        marginTop:'10px',

        height: '30px',
        width: '100%',
    },
});

export default function List({list,label,setList,readOnly}) {

    const classes = useStyles()

    const handleInputChange =(e,i)=>{
        const temp = [...list];
        temp[i] = e.target.value;
        setList(temp)
    }
    const handleAddList =()=>{
        const temp = [...list]
        temp.push('')
        setList(temp)
    }

    const handleDeleteList =(i)=>{
        const temp = [...list]
        temp.splice(i, 1)
        setList(temp)
    }
    return (
       <Paper className={classes.paper}>
           <Typography variant='h3'>{label}</Typography>
            {
                list.map((v,i)=>(
                    
                    <Grid className={classes.grid} key={i}  container spacing={2}>

                    <Grid item xs={10}>
                        <TextField  InputProps={{
            readOnly
          }} multiline className={classes.textField} type='text' defaultValue={v} onChange={(e)=>handleInputChange(e,i)}  />
                    </Grid>

                  
                     <Grid item xs={2}>
                    {  readOnly ?'':<Button variant='contained' color='secondary' className={classes.btn} onClick={()=>handleDeleteList(i)} >Delete</Button>}
                    </Grid>
                    
                  
                    </Grid>


                    
                    ))
            }
             { readOnly ?'':<Button variant='contained' color='primary'  className={classes.btn1} onClick={()=>handleAddList()}         startIcon={<Icon icon={plusFill} />}
                        />}
    </Paper>
    )
}
