




import { useState,useEffect } from 'react';
// material
import { Container, Typography,TablePagination } from '@material-ui/core';
// components
import axios from 'axios'
import Page from '../components/Page';
import {
  ProductList,
  // ProductCartWidget,
} from '../components/_dashboard/products';
//
import getApiUrl from '../utils/api'

// ----------------------------------------------------------------------
const url =getApiUrl()

export default function EcommerceShop() {

  const [page,setPage] = useState(0)
  const [total,setTotal] = useState(0)
  const [rowsPerPage,setRowsPerPage] = useState(10)
  const [user,setUser] = useState([])


  useEffect(async() => {
    const fetchAllUser = async()=>{
        const result = await axios.get(`${url}/user`,{params: {page:page+1,items:rowsPerPage}})
        setTotal(result.data.data[0].total[0].total)
        setUser(result.data.data[0].result)
    }
    fetchAllUser()

  },[page,rowsPerPage])

  const handleChangePage = (event,newPage)=>{
    setPage(newPage);

  }

  const handleChangeRowsPerPage = (event)=>{
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

 

  return (
    <Page title="Dashboard: User | 全民地产">
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Users
        </Typography>

         

        <ProductList user={user} />
        {/* <ProductCartWidget /> */}
        <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
      </Container>
    </Page>
  );
}
