import { Link as RouterLink } from 'react-router-dom';
// material
import axios from 'axios';
import{useState,useEffect} from 'react'
import { Box, Card, Link, Typography, Stack,Modal,Paper,TableRow,
  TableBody,
  TableCell,
  TableHead,
  Table

} from '@material-ui/core';
import { GoogleMap, useJsApiLoader,Marker } from '@react-google-maps/api';
import { makeStyles } from '@material-ui/styles';
import { styled } from '@material-ui/core/styles';
// utils
import getApiUrl from '../../../utils/api'
//
import Label from '../../Label';
import getAvatar from '../../../utils/avatar'

// ----------------------------------------------------------------------

const ProductImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});

const useStyles = makeStyles({
    paper:{
      boxShadow:"0px 2px 1px -1px rgba(0,0,0,0.3),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.32)",
      '&:hover':{ 
        backgroundColor:'#fbe9e9',

      }
    },
    modal:{
      position:'absolute',
      width:'80%',
      left:'10%',
    },
    paper2:{
      backgroundColor:'white',
      padding:'5%',
      width:'100%'
    },
    containerStyle:{
      width:'100%',
      height:'50vh',
    },
    
})

// ----------------------------------------------------------------------



const avatar =getAvatar()
const url =getApiUrl()

export default function ShopProductCard({ v,i }) {
  const classes =useStyles()
 
  const { userName,  phone, email, status,  } = v;
  const [open,setOpen] = useState(false)
  const [realty,setRealty] = useState([])
  const [id,setId] = useState('')
  const [center,setCenter] = useState({})
  

  const apiKey = process.env.REACT_APP_MAP_API

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey:apiKey
  })

  const handleOpen =()=>{
    setId(v._id)
    setOpen(true)
  }

  const handleClose =()=>{
    setOpen(false)
  }

  const handleSelectRealty = (v)=>{

  }

  useEffect(()=>{
     navigator.geolocation.getCurrentPosition((position)=>{
        const {latitude,longitude}=position.coords
        setCenter({lat: parseFloat(latitude),lng:parseFloat(longitude)})
     })
  },[])
  

  useEffect(()=>{
    const handleFetchUserLike= async()=>{
      const result = await axios.get(`${url}/user/saves`,{params:{userId:id}})
      setRealty(result.data.data)
}
if(id){

  handleFetchUserLike(id)
}
  },[id])

  return (
    <>
    <Card className={classes.paper} onClick={()=>handleOpen()}>
      <Box sx={{ pt: '100%', position: 'relative' }}>
        {status && (
          <Label
            variant="filled"
            color={(status === 'sale' && 'error') || 'info'}
            sx={{
              zIndex: 9,
              top: 16,
              right: 16,
              position: 'absolute',
              textTransform: 'uppercase'
            }}
          >
            {status}
          </Label>
        )}
        <ProductImgStyle alt={userName} src={avatar[i]} />
      </Box>

      <Stack spacing={2} sx={{ p: 3 }}>
        <Link to="#" color="inherit" underline="hover" component={RouterLink}>
          <Typography variant="subtitle1" noWrap>
            Name:{userName}
          </Typography>
        </Link>

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="subtitle1">
            <Typography
              component="span"
              variant="body1"
              sx={{
                color: 'black',
              }}
            >
              phone:{phone}
            </Typography>

           
           
          </Typography>
          
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="subtitle2">
            <Typography
              component="span"
              variant="body1"
              sx={{
                color: 'black',
              }}
            >
              email:{email}
            </Typography>

           
           
          </Typography>
          
        </Stack>
      </Stack>
    </Card>
    <Modal
    className={classes.modal}
    open={open}
    onClose={()=>handleClose()}
    >
      <Paper className={classes.paper2}>

      <Typography variant="h3"> 感兴趣的房子 </Typography>    
      
      <Table>
         
        <TableHead>
          <TableRow>

            <TableCell >
                Address
            </TableCell>

            <TableCell >
                ZipCode
            </TableCell>

            <TableCell >
                City
            </TableCell>

            <TableCell >
                Price
            </TableCell>

            <TableCell >
                MLS
            </TableCell>

          </TableRow>
        </TableHead>

        <TableBody>
          {
            realty ? realty.map((m,n)=>(
              <TableRow onClick={()=>handleSelectRealty(m)} key={n}>
                <TableCell align="left">
                    {m.address}
                </TableCell>
                <TableCell align="left">
                    {m.zipCode}
                </TableCell>
                <TableCell align="left">
                    {m.city}
                </TableCell>
                <TableCell align="left">
                    {m.price}
                </TableCell>
                <TableCell align="left">
                    {m.mls}
                </TableCell>
              </TableRow>
            )) : <TableRow><TableCell>没有感兴趣的房子</TableCell></TableRow>
          }
        </TableBody>
      </Table>   

      {isLoaded&&<GoogleMap
                        mapContainerClassName={classes.containerStyle}
                        center={center}
                        zoom={12}
                       
                        >
                        {
                          realty&&realty.map((m,n)=>(
                            <Marker
                            key={n}
                            position={{lat:parseFloat(m.lat),lng:parseFloat(m.lng)}}
                            label={(n+1).toString()}
                            />
                          ))
                        }
                        </GoogleMap>}
    
      </Paper>
    </Modal>
    </>
  );
}
